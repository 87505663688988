<template>

    <div class="card" style="margin-bottom:10px">

           <div class=" card-body">

             <div class="row">

               <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" style="margin-bottom:10px;  justify-content: space-between;display: flex;">
               
                   <h5 class="card-title mb-0 text-uppercase font-weight-bold" style="font-size: 12px;">Saldo disponible</h5>
                   <div  v-if="!loading" @click="refresh"  style="cursor:pointer;position: relative; top:-5px"> 
                     <i style="font-size: 6px" class="fas fa-sync text-sm opacity-10"></i>
                   </div>
                   
               </div>

               <div class="col-xl-7 col-lg-7 col-md-7 col-7 col-sm-7">
                 <Skeletor v-if="loading" :shimmer="true" height="38" width="100%" style="margin-bottom:0px"/>
                 <p v-else class="font-weight-bolder priceCard"><span style="font-size:14px">$</span> {{ balance }}.00</p>
               </div>

               <div class="col-xl-5 col-lg-5 col-md-5 col-5 col-sm-5" style="display:flex">
                 <button @click="retirar()" class="btn btn-primary btn-sm col-10" type="button" style="width:100%;padding: 5px;margin:auto">Retirar</button>
               </div>



             </div>
           </div>

         </div>

</template>
<script>

/* eslint-disable */

export default {
    name: "saldo-default",
    components: {





    },

    data() {

      let datos = JSON.parse(this.$store.state.user);
      
        return {
            loading: true,
            balance: 0,
            idUser: datos.code,//JSON.parse(localStorage.getItem('userData')).code,
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization')

        }
    },
    computed: {

    },
    watch: {

    },

    activated() {

        /* this.$eventBus.on('showEnviosSms', (parametro) => {            
            this.cantidadEnvios = parametro.contadorEnvios
            this.loading = false           
        }); */


    },
    mounted() {

      this.$eventBus.on('showCargaSaldos', () => {
            this.getSaldos();
        });

        this.$eventBus.emit('showModalRetiro'); 

    },
    methods: {

        prueba() {

            console.log('Biiiien')

        },

        refresh(){
          this.loading = true
          this.getSaldos()
        },

        async getSaldos(){

          const response = await this.$https.post('/api/v1/getSaldos', {
              idUser: this.idUser,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.tokenAuth,
                        // Otros encabezados si es necesario
                    },
                });

                //console.log('Saldos');

                //console.log(response);

                if(response.data.code === 200){
                  this.loading = false
                  this.balance = response.data.balance
                }else{
                  this.loading = false
                }

        },


        retirar(){

          if(Number(this.balance) === 0 || (this.balance) < 10 ){
            //console.log('La cantidad minima a retirar es de $5.00');

            this.$swal({
                            title: 'La cantidad minima a retirar es de $10.00',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Entendido',
                            focusConfirm: true,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });


            return
          }
   
this.$eventBus.emit('showModalRetiro', this.balance);                                      

},
    }

}
</script>