<template>
    <div class="card" :class="{ 'card2': isMobile, 'margin_card': !isMobile }" style="background: #001fd0;">

        <div class=" card-body">

            <div class="row">


                <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12">

                    <h6
                        style="font-size:14px;     color: white;font-weight: bold;text-align:center;margin-bottom: 20px; line-height: 15px;">
                        ¡Arma tu Equipo para Ganar Dinero con JubonesNet!</h6>


                </div>



                <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12">


                    <div class="input-group justify-content-center">
                        <input style="background-color: #e9ecef; color: black;" type="text" required v-model="link"
                            :readonly="true" class="form-control col-lg-6 text-center" id="link" ref="link"
                            aria-describedby="button-addon2">
                        <button class="btn-outline-secondary btn2" style="background: #e9ecef;color: #000;"
                            @click="copyInputValue('link')">
                            <i class="fa fa-clone" aria-hidden="true" style="margin-right: 5px;"></i>
                        </button>
                    </div>

                    <hr class="horizontal dark" style="margin-bottom: 10px;" />                    


                </div>





            </div>
        </div>



    </div>
</template>
<script>

export default {
    components: {



    },
    props: {
        prueba: { type: String, required: true }
    },
    data() {

        let datos = JSON.parse(this.$store.state.user);
        return {
            loading: true,
            link: '',
            idUser: datos.code //JSON.parse(localStorage.getItem('userData')).code,
        }
    },
    computed: {

    },
    watch: {

    },

    activated() {
        //console.log(this.prueba.slice(0, -3))
    },
    mounted() {

        setInterval(() => {
            this.loading = false;
        }, 3000);

        this.link = window.location.host + '/invitacion/' + this.idUser;


    },
    methods: {

        copyInputValue(model) {


            const inputElement = document.getElementById(model);
            inputElement.select();
            document.execCommand('copy');
            inputElement.setSelectionRange(0, 0);
            this.$toast.success("Link Copiado exitosamente", {
                position: "top-right",
                max: 10
            })


        }



    },
}
</script>
        
<style scope></style>