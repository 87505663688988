/* eslint-disable */
import { JSEncrypt } from 'jsencrypt'
import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";

import EditarPerfil from "../views/Dashboard/EditarPerfil";
import Perfil from "../views/Perfil.vue";
import Descripcion from "../views/Descripcion";
import Bancos from "../views/DatosBancarios";
import Invitacion from "../views/Referidos/Invitacion";
import MiEquipo from "../views/Referidos/MiEquipo";
import Transacciones from "../views/Dashboard/Transacciones";
import Cobertura from "../views/Cobertura/GoogleMaps";
import Calendar from "../views/GoogleCalendar.vue";

import NotFound from "../views/404.vue";
import keys from '@/store/keys';


import Terminos from "../views/Terminos.vue";
import Mas from "../views/Mas.vue";
import Password from "../views/Password.vue";

import Administracion from "../views/Administracion/Index.vue";




const routes = [
  {
    path: "/",
    name: "login",
    redirect: "/login",
    meta: { layout: 'full' }
  },
  {
    path: "/invitacion/:promoCode?",
    name: "Invitacion",
    component: Invitacion,
    meta: { layout: 'full' }
  },
  {
    path: "/dashboard/editarPerfil",
    name: "EditarPerfil",
    component: EditarPerfil,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Editar Perfil" }
  },
  {
    path: "/dashboard/descripcion",
    name: "Descripcion",
    component: Descripcion,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Descripción y Términos de Jubones Net" }
  },
  {
    path: "/Registrarse/Perfil",
    name: "Perfil",
    component: Perfil,
    meta: { requiresAuth: false, layout: 'full', breadCrumb: "Registrarse" }
  },
  {
    path: "/dashboard/Bancos",
    name: "Bancos",
    component: Bancos,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Registre su cuenta Bancaria" }
  },  
  {
    path: "/dashboard/MiEquipo",
    name: "MiEquipo",
    component: MiEquipo,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Mi Equipo de ventas" }
  }, 
  {
    path: "/dashboard/mas",
    name: "mas",
    component: Mas,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Otras opciones" }
  },
  {
    path: "/dashboard/terminos",
    name: "terminos",
    component: Terminos,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Términos y condiciones" }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Inicio" }
  },
  {
    path: "/dashboard/administracion",
    name: "Administracion",
    component: Administracion,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Administración", requiredRole: ['administrador'] }
  },
  {
    path: "/dashboard/transacciones",
    name: "transacciones",
    component: Transacciones,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Mis Transacciones" }
  },
  {
    path: "/dashboard/cobertura",
    name: "cobertura",
    component: Cobertura,
     meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Cobertura Google Maps", requiredRole: ['administrador', 'supervisor'] }
  },
  {
    path: "/dashboard/calendar",
    name: "calendar",
    component: Calendar,
     meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Calendario", requiredRole: ['administrador', 'supervisor'] }
  },
  {
    path: "/login",
    name: "Signin",
    component: Signin,
    meta: { layout: 'full' }
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { layout: 'full' }
  },
  {
    path: "/dashboard/password",
    name: "password",
    component: Password,
    meta: { requiresAuth: true, layout: 'full' }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { layout: 'full' }
  }


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});





router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    const userData = localStorage.getItem("userData");
    const user = userData;
    
    if (!user) {
      next('/login');
    } else {
      // Verificar si la ruta requiere un rol específico
      const requiredRole = to.meta.requiredRole;
      if (requiredRole) {
        const session = localStorage.getItem('session');
        const decrypt = new JSEncrypt();
        decrypt.setPrivateKey(keys.publicKeyBackoffice);
        const userRole = decrypt.decrypt(session);

        /* console.log('userRole: ' + userRole);
        console.log('requiredRole: ' + requiredRole); */
        
        if (Array.isArray(requiredRole) && requiredRole.includes(userRole)) {
          next();
        } else {
          // Si el usuario no tiene el rol requerido, redirige a la página de inicio o donde desees.
          next('/dashboard'); // O cualquier otra ruta que desees.
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});






router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;