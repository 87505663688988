<template>
  <div class="row">

    <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" align="center">
      <h4 style=" margin-bottom: 10px; line-height: 28px; color: #67748e;" class="text-center">Datos de su
        cuenta Bancaria</h4>
      <hr class="horizontal dark">
      <p style=" color: red; "><b>Recuerda que la cuenta debe estar a tu nombre para recibir la transferencia.</b></p>
      <form role="form" autocomplete="off" @submit.prevent="sendCash">
        <div class="row">


          <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 text-center">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-6 col-sm-6 col-xl-6 text-center">
                <div v-if="loading" class="mb-2">
                  <Skeletor height="20" width="70%" style="margin-bottom: 0px; margin-top:10px" />
                  <Skeletor height="20" width="70%" style="margin-bottom: 0px; margin-top:10px" />
                  <Skeletor height="20" width="70%" style="margin-bottom: 0px; margin-top:10px" />
                </div>
                <div v-else>
                  <p class="p_text"><span class="textLabel">Banco: </span> {{ user.nameBanco }}</p>
                  <p class="p_text"><span class="textLabel">Nro. cuenta: </span> {{ user.numberBank }}</p>
                  <p class="p_text"><span class="textLabel">Tipo: </span>{{ user.typeBank }}</p>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-6 col-sm-6 col-xl-6 text-center">
                <div v-if="loading" class="mb-2">
                  <Skeletor height="20" width="70%" style="margin-bottom: 0px; margin-top:10px" />
                  <Skeletor height="20" width="70%" style="margin-bottom: 0px; margin-top:10px" />
                  <Skeletor height="20" width="70%" style="margin-bottom: 0px; margin-top:10px" />
                </div>
                <div v-else class="mb-2">
                  <p class="p_text"><span class="textLabel">Dni: </span>{{ user.dni }}</p>
                  <p class="p_text"><span class="textLabel">Nombres: </span>{{ user.nombres }}</p>
                  <p class="p_text"><span class="textLabel">Correo: </span>{{ user.email }}</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12  text-center">

            <div v-if="loading" class="mb-2">
              <Skeletor height="40" width="100%" style="margin-bottom: 0px; margin-top:10px" />
            </div>

            <div v-else>
              <cleave v-model="user.saldo" :options="number" class="form-control autonumeric_input" placeholder="$0.00"
                required type="text" inputmode="decimal" :readonly="isDisable" />
            </div>


          </div>

          <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12  text-center">


            <button class="btn btn-primary btn-sm" style="width: 100%;" :disabled="isDisable" type="submit">Retirar
              dinero</button>


          </div>

        </div>


      </form>
    </div>

  </div>
</template>
    
<script>
/* eslint-disable */
import Saldos from "./Saldos.vue";

export default {
  components: {
    Saldos
  },
  props: ['userId', 'cashMoney'],
  data() {
    let datos = JSON.parse(this.$store.state.user);

    return {
      isDisable: true,
      loading: true,
      retiroCash: "",
      cashDisponible: '0',
      tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
      idUser: datos.code,//JSON.parse(localStorage.getItem('userData')).code,
      user: {
        nameBanco: '',
        numberBank: '',
        typeBank: '',
        saldo: '',
        dni: '',
        nombres: '',
        email: ''
      },
      number: {
        prefix: '$',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralIntegerScale: 9,
        numeralDecimalScale: 2
      },
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

    /* this.$eventBus.on('reiniciarSaldos', () => {
      this.componentKeySaldos += 1
    }); */


    this.getSaldo()

  },
  methods: {

    getSaldo() {


      this.$https.post('api/v1/getloadBancosUser', { idUser: this.idUser }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.tokenAuth,
        }
      }).then(response => {

        //console.log(response);


        if (response.data.code === 200) {

          this.loading = false;
          this.isDisable = false;

          this.user.nombres = response.data.datos[0].displayName;
          this.user.dni = response.data.datos[0].dni;
          this.user.email = response.data.datos[0].email;
          this.user.nameBanco = response.data.datos[0].bank;
          this.user.numberBank = response.data.datos[0].numberAccount;
          this.user.typeBank = response.data.datos[0].type;
          this.user.saldo = response.data.datos[0].balanceVentas + '.00'

        } else if (response.data.code === 204) {

          this.$swal({
            title: `Es necesario tener una cuenta registrada.`,
            icon: 'question',
            width: '400px',
            showCancelButton: true,
            confirmButtonText: 'Registrar cuenta ?',
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-secondary ml-1',
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {

            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {

              this.$router.push({ name: 'Bancos' })
              this.$eventBus.emit('showModalRetiro2');

            } else {
              // Acción a realizar cuando el usuario hace clic en "Cancelar"
              this.$router.push({ name: 'Bancos' })
              this.$eventBus.emit('showModalRetiro2');
            }
          });

        } else if (response.data.code === 208) {



          this.$swal.fire({
            title: response.data.message,
            icon: "warning",
            showConfirmButton: false,
          });
        }


      }).catch(error => {

        this.$toast.error("Ocurrió un error inesperado", {
          position: "top-right",
          max: 10
        })

      })

    },


    sendCash(event) {
      event.preventDefault();

      this.$swal({
        title: `La solicitud se enviará a validación`,
        icon: 'question',
        width: '400px',
        showCancelButton: true,
        confirmButtonText: 'Enviar solicitud',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {



        },
        allowOutsideClick: () => !this.$swal.isLoading(),

      }).then((result) => {
        if (result.isConfirmed) {




          this.$swal
            .fire({
              html: "<span style='font-size:14px'>Procesando, por favor espere.. </span>",
              position: "center",
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading();
              },
            })




          this.$https.post('api/v1/getSolicitudRetiro', { promoCode: this.idUser }, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": this.tokenAuth,
            }
          }).then(response => {

            if (response.data.code === 206) {

              this.$eventBus.emit('showModalRetiro2');

              this.$swal({
                title: 'CONTROL DEL SISTEMA',
                icon: 'info',
                text: response.data.message,
                showCancelButton: false,
                confirmButtonText: 'Entendido',
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });


            } else if (response.data.code === 200) {

              this.$eventBus.emit('showModalRetiro2');

              this.$swal({
                title: 'Solicitud enviada',
                icon: 'success',
                text: 'Su solicitud debe ser validada por nuestro equipo de administración.',
                showCancelButton: false,
                confirmButtonText: 'Entendido',
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });

            } else if (response.data.code === 208) {

              this.$swal.fire({
                title: response.data.message,
                icon: "warning",
                showConfirmButton: false,
              });

              this.$eventBus.emit('showModalRetiro2');

              return
            }


          }).catch(error => {

            this.$eventBus.emit('showModalRetiro2');

            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10
            })

          })


        }

      });


    },




  }
}
</script>
    
<style scoped>

.swal2-popup {
  display: inline-table !important;
}

.swal2-popup.swal2-modal.swal2-loading.swal2-show {
  display: inline-table !important;
}

.swal2-container {
  overflow-x: hidden !important;
  white-space: nowrap !important;
}

.swal2-container {
  overflow-x: hidden;
  white-space: nowrap;
}

</style>
