
<template>
    <div>
        <div class="row">

            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-3">

            </div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6">
                <div class="card mt-3">
                    <div class="card-header pb-0">

                        <p class="mb-0" style="text-align: center;">INFORMACIÓN BANCARIA</p>

                        <hr class="horizontal dark" />
                    </div>
                    <form role="form" autocomplete="off" @submit.prevent="onSubmitCuenta">
                        <div class="card-body">
                            <div class="row">


                                <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12" style="margin-bottom:25px">
                                    <label for="example-text-input" class="form-control-label">Institución bancaria</label>
                                    <Skeletor v-if="loading" :shimmer="true" height="60" width="100%"
                                        style="margin-bottom: 0px;" />
                                    <v-select v-else placeholder="Seleccione un banco" style=" height: 30px; " label="Banco"
                                        v-model="bank" :disabled="isDisable" :options="banksArray"
                                        @option:selected="setSelected">
                                        <template #search="{ attributes, events }">
                                            <input class="vs__search" :required="!bankCode" v-bind="attributes"
                                                v-on="events">
                                        </template>
                                    </v-select>


                                </div>

                                <div class="col-md-6" style="margin-bottom:10px">
                                    <label for="example-text-input" class="form-control-label">Tipo de cuenta</label>
                                    <Skeletor v-if="loading" :shimmer="true" height="40" width="100%" />
                                    <select v-else v-model="type" :disabled="isDisable" class="form-select"
                                        placeholder="Seleccione una opción" required>
                                        <option selected value="">Seleccione una opción</option>
                                        <option value="Ahorro">Ahorro</option>
                                        <option value="Corriente">Corriente</option>

                                    </select>

                                </div>

                                <div class="col-md-6" style="margin-bottom:10px">
                                    <label for="example-text-input" class="form-control-label">Nro. Cuenta
                                        bancaria(*)</label>
                                    <Skeletor v-if="loading" :shimmer="true" height="40" width="100%" />
                                    <input v-else class="form-control" :disabled="isDisable" type="number"
                                        placeholder="Ingrese su número de cuenta" v-model="numberAccount" required />
                                </div>




                                <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12 d-flex justify-content-center mt-3"
                                    align="center">

                                    <Skeletor v-if="loading" :shimmer="true" pill height="10" width="70%" />
                                    <div v-else class="form-check" style="margin-bottom:10px">
                                        <input class="form-check-input form-control" type="checkbox" required
                                            v-model="terminos" id="flexCheckDefault" v-on:click="toggleBoton">
                                        <label class="form-check-label" for="flexCheckDefault"
                                            style=" font-weight: bold; font-size: 11px; ">
                                            Confirmo que la cuenta bancaria registrada es de mi pertenencia
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"> </div>

                                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" align="center"
                                    style="margin-top:15px; margin-bottom:15px">
                                    <button id="btn_registrar" type="submit" class="btn btn-primary"
                                        :disabled="botonHabilitado" style="width: 100%;">
                                        Actualizar cuenta bancaria
                                    </button>
                                </div>


                                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>

                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                    <div class="alert alert-primary" role="alert" style="    margin: 0px;font-size:12px">

                                        Los datos de la cuenta bancaria a registrarse deben corresponder con la información
                                        personal registrada en su perfil de JUBONES NET.<br> No se aceptan cuentas bancarias
                                        de
                                        terceras personas, caso contrario, sus solicitudes de retiro de dinero serán
                                        rechazadas. <br>Esta cuenta bancaria será usada para el depósito de sus comisiones.

                                    </div>



                                </div>
                            </div>


                        </div>
                    </form>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-3">

            </div>

        </div>
    </div>
</template>

<script>
/*eslint-disable */


export default {
    name: "Banco",
    data() {

        let datos = JSON.parse(this.$store.state.user);


        return {
            isDisable: false,
            idUser: datos.code, //JSON.parse(localStorage.getItem('userData')).code,
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            isMobile: this.$isMobile,
            loading: true,
            botonHabilitado: true,
            terminos: false,
            banksArray: [],
            bankCode: "",
            numberAccount: "",
            type: "",
            bank: "",
            nameBank: ''
        }
    },
    activated() {

        if (this.isMobile) {
            this.$eventBus.emit('activeShowMenu')
        }




    },
    methods: {
        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                max: 10
            });
        },

        async onSubmitCuenta(event) {

            event.preventDefault()

            const data_json = {
                idUser: this.idUser, numberAccount: this.numberAccount, bankCode: this.bankCode, nameBank: this.nameBank, type: this.type,
            }

            //console.log(data_json);

            //const dataEncripted = this.$encryptBackoffice.encrypt(data_string);

            /* this.isDisable = true;
            this.botonHabilitado = true; */

            document.getElementById('btn_registrar').innerHTML = '<i class="fas fa-spinner fa-pulse"></i>  Actualizando'


            try {

                const response = await this.$https.post("api/v1/getUpdateBancos", data_json, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.tokenAuth,
                    }
                });


                if (response.data.code === 200) {
                    document.getElementById('btn_registrar').innerHTML = 'Actualizar cuenta bancaria'
                    this.showSuccessToast(response.data.message)
                    this.loadBancoUser()
                }

            } catch (error) {
                document.getElementById('btn_registrar').innerHTML = 'Actualizar cuenta bancaria'
                this.showErrorToast(error.response.message)
            }



            //





        },

        setSelected(value) {

            //console.log(value);
            this.bankCode = value.Codigo
            this.nameBank = value.Banco

        },

        toggleBoton() {

            this.botonHabilitado = !this.botonHabilitado;
        },

        async loadBanco() {

            this.loading = true;

            //Cargar los bancos

            const data = {
                dni: '',
            };

            const response = await this.$https.post("api/v1/getBancos", data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.tokenAuth,
                }
            });

            //console.log(response.data.code);

            if (response.data.code === 200) {
                this.loading = false;
                this.banksArray = response.data.datos
            }


        },

        async loadBancoUser() {

            try {

                const response = await this.$https.post("api/v1/getloadBancosUser", { idUser: this.idUser }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.tokenAuth,
                    }
                });

                if (response.data.code === 200) {
                    this.numberAccount = response.data.datos[0].numberAccount
                    this.type = response.data.datos[0].type
                    this.bank = response.data.datos[0].bank
                }

            } catch (error) {
                this.showErrorToast(error.response.message)
            }


        }


    },
    mounted() {

        this.loadBanco();

        this.loadBancoUser();


    }
}    
</script>


<style scoped>
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.vs__search {
    height: 30px;
}
</style>