
<template>
    <div>
        <modal-photo v-if="showModalPhoto" :image="image" />
        <div class="row mt-3">

            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-3">

            </div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6">
                <div class="card">

                    <div class="card-body">

                        <div class="row" style=" margin-bottom: 15px;">




                            <div style="position: relative;" class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12"
                                align="center">


                                <v-lazy-image :src="photo" class="rounded-circle img_profile"
                                    style="border: 4px solid white;width: 100px" />

                                <input type="file" id="upload" ref="profilePhoto" accept="image/jpeg, image/jpg"
                                    @change="loadImage($event)" hidden />
                                <label for="upload" class="camera_photo"> <i style="margin: auto;"
                                        class="fas fa-camera"></i></label>




                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12" align="center">

                                <p
                                    style="margin-bottom:0px; margin-top:15px;    line-height: 18px; text-align: center;font-size:15px; font-weight: bold;">
                                    {{ displayName }}</p>
                                <p style="margin-bottom:15px; text-align: center;font-size:13px">{{ dni }}</p>

                                <form role="form" autocomplete="off" @submit.prevent="onSubmit">

                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                            style="margin-bottom:10px;    text-align: left;">
                                            <label for="example-text-input" class="form-control-label"
                                                style="font-weight: bold; font-size: 12px; text-align: left;">Email
                                                (*):</label>
                                            <Skeletor v-if="loading" :shimmer="true" height="40" width="100%" />
                                            <input v-else :disabled="isDisabled"
                                                placeholder="Ingrese su email" class="form-control col-lg-12" required
                                                type="email" autocomplete="off" v-model="email" />
                                            <!-- <p style="margin:0px; text-align: left;">{{ email }}</p> -->

                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                            style="margin-bottom:10px;    text-align: left;">
                                            <label for="example-text-input" class="form-control-label">Dni (*):</label>
                                            <Skeletor v-if="loading" :shimmer="true" height="40" width="100%" />
                                            
                                            <input v-else :disabled="isDisabled"
                                                placeholder="Ingrese su dni" class="form-control col-lg-12" required
                                                type="text" autocomplete="off" v-model="dni" />


                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                            style="margin-bottom:10px;    text-align: left;">
                                            <label for="example-text-input" class="form-control-label">Estado civil
                                                (*):</label>
                                            <Skeletor v-if="loading" :shimmer="true" height="40" width="100%" />
                                            
                                            <input v-else :disabled="isDisabled"
                                                placeholder="Ingrese su estado civil" class="form-control col-lg-12" required
                                                type="text" autocomplete="off" v-model="estadoCivil" />


                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                            style="margin-bottom:10px;    text-align: left;">
                                            <label for="example-text-input" class="form-control-label"
                                                style="font-weight: bold; font-size: 12px; text-align: left;">Fecha Nac
                                                (*):</label>
                                            <Skeletor v-if="loading" :shimmer="true" height="40" width="100%" />
                                            <input v-else :disabled="isDisabled" placeholder="Ingrese su ciudad"
                                                class="form-control col-lg-12" required type="text" autocomplete="off"
                                                v-model="nacimiento" />


                                        </div>

                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">

                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <!-- <Skeletor v-if="loading" :shimmer="true" height="40" width="100%" /> -->
                                            <button
                                                id="btn_guardar"
                                                
                                                type="submit"
                                                class="btn btn-primary"
                                                style="width: 100%;margin-top:15px"
                                                :disabled="isDisabled"
                                            >
                                                Actualizar
                                            </button>

                                        </div>




                                    </div>



                                </form>


                            </div>



                        </div>

                    </div>


                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-3">

            </div>

        </div>
    </div>
</template>

<script>
/*eslint-disable */
import ModalPhoto from "./ModalPhoto.vue";
export default {
    name: "EdicionPerfil",
    components: {
        ModalPhoto
    },
    data() {

        //let datos = JSON.parse(this.$store.state.user);
        let optionsGeneros = [
            { "id": "Masculino", "value": "Masculino" },
            { "id": "Femenino", "value": "Femenino" },
            { "id": "Transgenero", "value": "Transgénero" },
        ]


        let optionsEstadoCivil = [
            { "id": "Soltero/a", "value": "Soltero/a" },
            { "id": "Divorciado/a", "value": "Divorciado/a" },
            { "id": "Casado/a", "value": "Casado/a" },
            { "id": "Viuda/o", "value": "Viuda/o" },
        ]



        return {
            userId: '',
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            permitirEmail: false,
            dni: JSON.parse(localStorage.getItem('userData')).dni,
            displayName: JSON.parse(localStorage.getItem('userData')).displayName,
            photo: JSON.parse(localStorage.getItem('userData')).photo,
            showModalPhoto: false,
            estadoCivil: '',
            nacimiento:'',
            loading: true,
            isDisabled: true,
            email: '',
            componentKeyModalPhoto: 100,
            type: "",
            image: {
                src: null,
                type: null
            }
        }
    },
    activated() {

        if (this.isMobile) {

            this.$eventBus.emit('inactiveShowMenu')
        }




    },
    
    methods: {

        getProfile() {
            this.loading = true;

        },

        onChange2(event) {



            this.genero = event.target.value;


        },



        onChange1(event) {



            this.estadoCivil = event.target.value;


        },

        onSubmit(event) {
            event.preventDefault();

            this.isDisabled = true;

            document.getElementById('btn_guardar').innerHTML = '<i class="fas fa-circle-notch fa-spin"></i> Actualizando'
            //document.getElementById('btn_guardar').innerHTML = 'Actualizar'


        },

        loadImage(event) {




            if (event.target.files[0].type === "image/jpeg") {

                this.type = "image/jpeg"

                const FileSizeBanner = event.target.files[0].size / 1024 / 1024
                // let foto=event.target.files[0];



                if (FileSizeBanner > 5) {
                    this.$refs.profilePhoto.value = null;

                    this.$toast.error("La imagen debe pesar menos de 5mb", {
                        position: "bottom-right",
                        max: 10
                    })
                } else {

                    // Referencia al elemento de entrada DOM
                    const { files } = event.target;
                    // Asegúrese de tener un archivo antes de intentar leerlo.
                    if (files && files[0]) {
                        // 1. Revocar la URL del objeto para permitir que el recolector de basura destruya el archivo cargado antes.
                        if (this.image.src) {
                            URL.revokeObjectURL(this.image.src)
                        }
                        // 2. Cree el vínculo del blob al archivo para optimizar el rendimiento:
                        const blob = URL.createObjectURL(files[0]);

                        // 3. Los pasos a continuación están diseñados para determinar un tipo de archivo mime para usarlo durante la obtención de una imagen recortada del lienzo. 
                        // Puedes reemplazarlos por la siguiente cadena, pero el tipo se derivará de la extensión y puede llevar a un resultado incorrecto:
                        
                        // this.image = {
                        //    src: blob;
                        //    type: files[0].type
                        // }

                        // Cree un nuevo FileReader para leer los datos binarios de esta imagen.
                        const reader = new FileReader();
                        // Defina una función de devolución de llamada para ejecutar cuando FileReader finalice su trabajo.
                        reader.onload = (e) => {
                            // Nota: aquí se utiliza la función de flecha, de modo que "this.image" se refiere a la imagen del componente Vue
                            this.image = {
                                // Establezca la fuente de la imagen (se verá como un blob: http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                                src: blob,
                                // Determine el tipo de imagen para conservarla durante la extracción de la imagen del lienzo:
                                type: this.type,
                            };
                        };
                        // Inicie el trabajo del lector: lea el archivo como una URL de datos (formato base64)
                        reader.readAsArrayBuffer(files[0]);

                        this.showModalPhoto = true;
                    }



                }


            } else {

                this.$refs.profilePhoto.value = null;

                this.$toast.error("El archivo debe tener una extensión JPG/JPEG", {
                    position: "bottom-right",
                    max: 10
                })

            }


        },

        async loaderPerfil(){

            const data = {
                    dni: this.dni,
                };

                const response = await this.$https.post("api/v1/getDatosUser", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.tokenAuth,
                    }
                });

                //console.log(response);

                if(response.data.code === 200){
                    this.loading = false
                    
                    this.email = response.data.datos.email;
                    this.estadoCivil = response.data.datos.estadoCivil;
                    this.nacimiento = response.data.datos.nacimiento;

                }
        }


    },
    mounted() {

        this.loaderPerfil()
        /* this.getProfile();

        this.$eventBus.on('reiniciarModalPhoto', () => {
            this.$refs.profilePhoto.value = null;

            this.componentKeyModalPhoto += 1;
            this.showModalPhoto = false
        }); */

        this.$eventBus.on('updateFotoPerfil', (url) => {
            this.showModalPhoto = false
            this.photo = JSON.parse(localStorage.getItem('userData')).photo
        }) 

    }
}    
</script>


<style scoped></style>