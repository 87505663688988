<template>
    <div class="card" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTADO DE TUS REFERIDOS</p>

                </div>


                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                    <i v-else @click="refresh" style="cursor:pointer; margin:auto"
                        class="fas fa-sync text-sm opacity-10"></i>
                </div>

            </div>

            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                <div class="row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="40" width="50%" />

                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                        <Skeletor :shimmer="true" height="40" width="100%" />

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="table tablesorter">
                            <thead class="thead-light">
                                <tr>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>

                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                    </div>

                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                <Skeletor :shimmer="true" circle size="30" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>


                        </div>


                    </div>

                </div>

            </div>

            <DataTable v-else :columns="columns" :options="options" :data="transactions" class="table tablesorter"
                width="100%">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Fecha/Hora</th>
                        <th data-priority="1">Nombres</th>
                        <th data-priority="2">Movil</th>
                        <th>Provincia</th>
                        <th>Cantón</th>
                        <th>Parroquia</th>
                        <th>Observación</th>
                        <th data-priority="3">Estado</th>
                        <th data-priority="4">Acción</th>

                    </tr>
                </thead>





            </DataTable>


        </div>

    </div>
</template>

<script>

/* eslint-disable */

import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';


DataTable.use(DataTablesCore);

export default {

    props: {
        userId: { type: String, required: true }
    },

    components: {

        DataTable


    },

    data() {

        let datos = JSON.parse(this.$store.state.user);

        return {
            idUser: datos.code, //JSON.parse(localStorage.getItem('userData')).code,
            loading: true,
            transactions: [],
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            columns: [
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        const index = meta.row + 1;
                        return index;
                    }
                },
                {
                    data: 'fech_registro',
                    render: function (data, type, row, meta) {
                        if (type === 'display' || type === 'filter') {
                            const fechaHora = new Date(data);
                            const fechaFormateada = fechaHora.toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            });
                            const horaFormateada = fechaHora.toLocaleTimeString('es-ES', {
                                hour12: false,
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            });
                            return `${fechaFormateada} ${horaFormateada}`;
                        }
                        return data;
                    }
                },
                { data: 'displayName' },
                { data: 'movil' },
                { data: 'provincia' },
                { data: 'canton' },
                { data: 'parroquia' },
                { data: 'observacion' },
                { data: 'estado' },
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        //console.log('Ingresa al render');
                        //console.log(row);
                        const datos = String(`valor1: ${data.movil} valor2: ${data.displayName} valor3: ${data.provincia} valor4: ${data.canton} valor5: ${data.parroquia} valor5: ${data.estado}`);
                        const id = data.movil//'id'//row.dni; // Obtén el ID de la fila                        
                        /* const repass = `<i data-id="${id}" data-action="repass" class="dataAction fas fa-sync-alt" style="cursor:pointer; margin-right: 10px; color: #344767;"></i>`; */
                        //const editIcon = `<i data-id="${datos}" data-action="edit" class="dataAction fas fa-edit" style="cursor:pointer; margin-right: 10px; color: #344767;"></i>`;
                        const deleteIcon = `<i data-id="${id}" data-action="delete" class="dataAction redes fas fa-trash-alt" style="cursor:pointer; color: red;"></i>`;
                        //return repass + editIcon + deleteIcon;
                        return deleteIcon;
                    },
                    className: 'column-centered'
                }

            ],
            options: {
                responsive: true,
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [5, 15, 25, 35, 50, -1],
                    [5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 5,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            },

        }
    },
    computed: {

    },
    watch: {

    },

    activated() {

        //console.log('Ingresa listado');
        //console.log(this.prueba)
        //console.log(this.tokenAuth)






        //this.getListado();

    },


    mounted() {


        this.$eventBus.on('showCargaLista', () => {
            //console.log('Recibe parametro del dashboard: ');
            this.getListado();
        });




        $(document).off('click', '.dataAction');
        $(document).on('click', '.dataAction', (evt) => {
            const id = $(evt.target).data('id');
            const action = $(evt.target).data('action');

            if (action === 'delete') {
                //console.log('delete: ' + id);
                this.deleteUser(id)

            }
        });





    },
    methods: {

        deleteUser(id) {
            //console.log('Viene metodo a eliminar: ' + id);


            this.$swal({
                title: `¿Seguro que deseas eliminar el referido?`,
                icon: 'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {


                    this.$https.post('api/v1/getDeleteReferidos', { idUser: this.idUser, movil: id }, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": this.tokenAuth,
                        }
                    }).then(response => {

                        //console.log(response);                                    

                        if(response.data.code === 200){

                            this.getListado()

                            this.$toast.success(response.data.message, {
                                position: "top-right",
                                max: 10
                            })

                        }else if(response.data.code === 204){

                            this.$swal({
                                title: response.data.message,
                                icon: 'warning',
                                showCancelButton: false,
                                confirmButtonText: 'Entendido',
                                focusConfirm: true,
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            });

                        }else if(response.data.code === 208){

                            this.$toast.error(response.data.message, {
                                position: "top-right",
                                max: 10
                            })

                        }


                    }).catch(error => {

                        this.$toast.error("Ocurrió un error inesperado", {
                            position: "top-right",
                            max: 10
                        })

                    })



                }
            })






        },

        refresh() {


            this.$eventBus.emit("reiniciarListadoVentas")
            this.loading = true
            this.getListado()


        },

        async getListado() {
            //console.log('Carga listado getListado');

            try {
                const response = await this.$https.post('/api/v1/getconsReferidosPromotorIndividual', {
                    code: this.idUser,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.tokenAuth,
                        // Otros encabezados si es necesario
                    },
                });

                //console.log(response);

                if (response.data.code === 200) {
                    //console.log(response.data.datos);
                    this.transactions = response.data.datos;
                } else if (response.data.code === 204) {
                    this.transactions = response.data.datos;
                } else {
                    console.log('error');
                }





            } catch (error) {
                console.error(error);
                // Tu código para manejar el error
                console.log("Error al cargar la lista");
                console.log(error.response.status);

                if (error.response.status === 401) {
                    this.$swal
                        .fire({
                            html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                            position: "center",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false, // Oculta el botón de confirmación
                            timer: 4000, // Duración en milisegundos (4 segundos)
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        })
                        .then(
                            () => {
                                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                console.log("closed by timer!!!!");

                                localStorage.clear();
                                location.reload(true);
                            },
                            (dismiss) => {
                                if (dismiss === "timer") {
                                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                    console.log("closed by timer!!!!");
                                    this.$swal.fire({
                                        title: "Finished!",
                                        icon: "success",
                                        showConfirmButton: false,
                                    });
                                }
                            }
                        );
                }


            } finally {
                this.loading = false;
            }
        },





    },
}
</script>

<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';


.column-centered {
    text-align: center;
}
</style>
  