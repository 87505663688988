<template>
    <VueFinalModal v-model="showModal" :esc-to-close="true" @closed="closed" class="modal-container"
        content-class="modal-content">
        <div class="modal__content">
            <div class="row">

                <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11">
                </div>

                <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1 ">
                    <i class="fa fa-times-circle" style="cursor: pointer;" @click="closed"></i>
                </div>

            </div>



            <form role="form" class="text-center" autocomplete="off" @submit.prevent="saveMarket">



                <div class="row">
                    <div class="col-lg-6 col-md-12 mx-auto">
                        <!-- Usa col-lg-6 para pantallas grandes y col-md-12 para móviles -->
                        <label for="example-text-input" class="form-control-label text-center"
                            style="font-weight: bold; font-size: 12px;">Ingrese la IP (*):</label>
                        <input placeholder="IP" class="form-control" v-model="marketSend.ip" required type="text"
                            autocomplete="off" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <label for="example-text-input" class="form-control-label"
                            style="font-weight: bold; font-size: 12px; text-align: left;">Ingrese la latitud (*):</label>
                        <input placeholder="Ingrese la latitud" v-model="marketSend.lat" class="form-control" required
                            type="text" autocomplete="off" />
                    </div>

                    <div class="col-lg-6">
                        <label for="example-text-input" class="form-control-label"
                            style="font-weight: bold; font-size: 12px; text-align: left;">Ingrese la longitud (*):</label>
                        <input placeholder="Ingrese la longitud" v-model="marketSend.lng" class="form-control" required
                            type="text" autocomplete="off" />
                    </div>
                </div>



                <div class="row">
                    <div class="col-12">
                        <label for="example-text-input" class="form-control-label"
                            style="font-weight: bold; font-size: 12px; text-align: left;">Ingrese alguna novedad
                            (*):</label>

                        <textarea required class="form-control" placeholder="Escriba alguna novedad..."
                            v-model="marketSend.descrip" rows="2"></textarea>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center mt-2">
                    <button type="submit" id="btn_submit" class="btn btn-primary" style="width: 40%;"> Guardar </button>
                </div>

            </form>

        </div>
    </VueFinalModal>


    <div class="mt-3" style="padding-left: 10px; padding-right: 10px; padding-left: 10px;">
        <!-- <h3 class="text-center mt-3">Comprobación de cobertura</h3> -->





        <div class="row mb-5">




            <div class="col-lg-6 mx-auto col-md-6 text-center">

                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Ingrese la IP" aria-label="Número de cédula"
                        aria-describedby="button-addon2" v-model="buscaIP">
                    <button class="btn-outline-secondary btn2" v-on:click="searchByIP" type="submit">
                        <i class="fa fa-search" aria-hidden="true" style="margin-right: 5px;"></i>
                        <b>Buscar</b>
                    </button>
                </div>

            </div>







            <form role="form" autocomplete="off" @submit.prevent="sendParametro">

                <div class="row" v-if="!notSupport">

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom:10px">
                        <label for="example-text-input" class="form-control-label"
                            style="font-weight: bold; font-size: 12px; ">Dirección de su domicilio (*):</label>
                        <GMapAutocomplete :value="autocomplete" placeholder="Dirección domiciliaria"
                            @place_changed="getAddressData($event)" required :readonly="isDisabled"
                            class="form-control col-lg-12"></GMapAutocomplete>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom:10px">
                        <label for="example-text-input" class="form-control-label"
                            style="font-weight: bold; font-size: 12px; ">Indique la ubicación de su domicilio (*):</label>
                        <GMapMap @click="mark" :center="center" :zoom="16" map-type-id="terrain"
                            style="width: 100%; height: 400px" :options="mapOptions">



                            <GMapMarker :key="index" v-for="(m, index) in predefinedMarkers" :position="m.position"
                                :clickable="true" 
                                :icon="'https://firebasestorage.googleapis.com/v0/b/referidos-sist.appspot.com/o/Documentation%2FiconMapa.png?alt=media&token=9c915d3f-569c-4c0e-bc73-b2f389b720b4'"
                                @click="openMarker(m)" >

                                <GMapInfoWindow :closeclick="true" @closeclick="openMarker(null)"
                                    :opened="openedMarkerID === m.id" >
                                    <!-- <div>I am in info window {{ m.id }} </div> :draggable="true" esto hace que se deplique la posición-->



                                    <div id="content">
                                        <span class="spanWindow text-center">
                                            <div class="text-center">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/referidos-sist.appspot.com/o/Documentation%2Fgiru.jpg?alt=media&token=cdfc2cad-6f41-4e44-bc3d-52ffad4ee82b"
                                                    alt="Profile Photo" style="width: 3rem; border-radius: 50%;">
                                            </div>
                                            <h6>{{ m.ip }}</h6>
                                        </span>

                                        <div class="text-center">
                                            <!-- <p class="data-local mb-1"><b>Ciudad:</b> {{ m.city }}</p> -->
                                            <p class="data-local"><b>Detalle:</b> {{ m.detalle }}</p>


                                            <!-- Direcciona al Google Maps -->
                                            <a class="btn btn-sm mt--2"
                                                style="color: #07325b !important; background-color: #ffbd59 !important; border-color: #ffbd59 !important; font-weight: 500;"
                                                :href="'https://www.google.es/maps?q=' + m.position.lat + ',' + m.position.lng"
                                                target="_blank">
                                                Cómo llegar
                                            </a>


                                        </div>




                                    </div>




                                </GMapInfoWindow>


                                <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position"
                                    :clickable="true"  @click="center = m.position"
                                    @dragend="handleMarkerDrag" />

                            </GMapMarker>







                        </GMapMap>
                    </div>

                </div>

                <div v-else class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">
                        <svg width="100" height="100" viewBox="-20 0 190 190" xmlns="http://www.w3.org/2000/svg">
                            <!-- ... SVG path data ... -->
                        </svg>
                        <div class="alert alert-primary" role="alert" style="margin: 0px;font-size:12px">
                            <p style="text-align:center;font-size:14px;margin: 0px;">Tu navegador no soporta la API de
                                geolocalización. Por favor intente con el navegador Chrome, Brave, Mozilla u otro.</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center mt-2">
                    <button type="submit" id="btn_submit2" class="btn btn-primary" style="width: 40%;"> Agregar Marcador
                    </button>
                </div>

            </form>
        </div>

        <div class="row">
            <listado-cobertura/>
        </div>

    </div>
</template>
  
<script>
/*eslint-disable */
import { VueFinalModal } from 'vue-final-modal';
import 'vue-final-modal/style.css';
import ListadoCobertura from "./ListadoCobertura.vue";

export default {
    name: "GoogleMaps",
    components: {
        VueFinalModal,
        ListadoCobertura
    },
    data() {

        
        return {
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            buscaIP: '',
            openedMarkerID: null,
            showModal: false,
            isDisabled: false,
            autocomplete: "",
            center: {},
            markers: [],
            
            predefinedMarkers: [
                /* {
                    id: 1,
                    position: {
                        lat: -3.240906804638259,
                        lng: -79.83784009682007
                    }
                },
                {
                    id: 2,
                    position: {
                        lat: -3.242051614465068,
                        lng: -79.83743240104981
                    }
                } */
            ],
            marketSend: {
                ip: "",
                lat: "",
                lng: "",
                descrip: ""
            },
            geocoder: null,
            loadMap: true,
            plusCode: "",
            notSupport: false,
            city: "",
            mapOptions: {
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                disableDefaultUi: true,
            },
            googleMarkers: [],
            map: null,
            isGoogleMapsLoaded: false,
        };


    },

     created() {

    },

    methods: {
        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "top-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "top-right",
                max: 10
            });
        },

        //Con esto buscamos la IP
        searchByIP() {
            // IP a buscar
            //const buscaIp = "192.168.0.3";
            const buscaIp = this.buscaIP.trim();

            // Busca la IP en el array de predefinedMarkers
            const resultadoBusqueda = this.predefinedMarkers.find((marcador) => marcador.ip === buscaIp);

            if (resultadoBusqueda) {
                // Si se encuentra la IP, actualiza la ubicación y los marcadores
                const { lat, lng } = resultadoBusqueda.position;
                this.center = { lat, lng };
                this.markers = [{ position: { lat, lng } }];

                // Realiza la búsqueda de la dirección utilizando geocoder
                this.geocoder.geocode({ location: this.center })
                    .then((response) => {
                        if (response.results[0]) {
                            this.plusCode = response.results[0].formatted_address;
                            this.autocomplete = response.results[1].formatted_address;
                            if (response.results[0].address_components[2]) {
                                this.city = response.results[0].address_components[2].long_name;
                            } else {
                                console.warn("No se pudo encontrar el componente de ciudad en la respuesta.");
                            }
                        }
                    })
                    .catch((e) => console.log("Geocoder failed due to: " + e));
            } else {
                // Si no se encuentra la IP, maneja el caso aquí
                console.log("IP no encontrada en nuevosMarcadores");
                this.showErrorToast('IP no encontrada')
            }
        },


        closed() {
            this.showModal = false;
        },

        openMarker(m) {

            if (m !== null) {
                this.openedMarkerID = m.id
            } else {
                this.openedMarkerID = null
            }

        },

        getAddressData(request) {

            console.log("entro 1");

            this.autocomplete = request.formatted_address;


            let ubicacion = request.geometry.location;
            this.center = {
                lat: parseFloat(request.geometry.location.lat()),
                lng: parseFloat(request.geometry.location.lng())
            };

            this.markers =
                [
                    {
                        position: ubicacion,
                    }, // Along list of clusters
                ]

            this.geocoder
                .geocode({ location: this.center })
                .then((response) => {
                    if (response.results[0]) {
                        console.log("entro 2");

                        this.plusCode = response.results[0].formatted_address;
                        this.autocomplete = response.results[1].formatted_address;

                        // Verifica la existencia del componente de ciudad antes de acceder a él
                        if (response.results[0].address_components[2]) {
                            this.city = response.results[0].address_components[2].long_name;
                        } else {
                            console.warn("No se pudo encontrar el componente de ciudad en la respuesta.");
                        }
                    }
                })
                .catch((e) => console.log("Geocoder failed due to: " + e));





        },

        loadMapa() {
            if (this.loadMap) {


                this.loadMap = false;

                if (!("geolocation" in navigator)) {

                    this.$toast.error("La geolocalización no está soportado para este navegador", {
                        position: "top-right",
                        max: 10
                    })





                    this.notSupport = true;


                    return;
                }

                // get position
                navigator.geolocation.getCurrentPosition(pos => {

                    this.center = {
                        lat: parseFloat(pos.coords.latitude),
                        lng: parseFloat(pos.coords.longitude)
                    };

                    this.markers =
                        [
                            {
                                position: {
                                    lat: parseFloat(pos.coords.latitude), lng: parseFloat(pos.coords.longitude)
                                },
                            }, // Along list of clusters
                        ]



                    this.geocoder
                        .geocode({ location: this.center })
                        .then((response) => {
                            if (response.results[0]) {
                                console.log("entro 3");

                                this.plusCode = response.results[0].formatted_address;
                                this.autocomplete = response.results[1].formatted_address;

                                // Verifica la existencia del componente de ciudad antes de acceder a él
                                if (response.results[0].address_components[2]) {
                                    this.city = response.results[0].address_components[2].long_name;
                                } else {
                                    console.warn("No se pudo encontrar el componente de ciudad en la respuesta.");
                                }
                            }
                        })
                        .catch((e) => console.log("Geocoder failed due to: " + e));



                }, err => {

                    this.$toast.error(err.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })




                })

            }
        },

        instanceMap() {
            this.geocoder = new google.maps.Geocoder();
            // Espera hasta que el mapa esté disponible
            this.$nextTick(() => {
                this.loadMapa();
                // Ahora puedes llamar a createMarkers después de que el mapa esté listo
                //this.createMarkers();
            });
        },

        handleMarkerDrag(e) {


            this.center = {
                lat: parseFloat(e.latLng.lat()),
                lng: parseFloat(e.latLng.lng())
            };


            this.geocoder
                .geocode({ location: this.center })
                .then((response) => {



                    if (response.results[0]) {
                        console.log("entro 4");

                        this.plusCode = response.results[0].formatted_address;
                        this.autocomplete = response.results[1].formatted_address;
                        this.city = response.results[0].address_components[2].long_name;




                    }
                })
                .catch((e) => console.log("Geocoder failed due to: " + e));




        },

        mark(event) {



            this.center = {
                lat: parseFloat(event.latLng.lat()),
                lng: parseFloat(event.latLng.lng())
            };

            this.markers =
                [
                    {
                        position: {
                            lat: parseFloat(event.latLng.lat()), lng: parseFloat(event.latLng.lng())
                        },
                    }, // Along list of clusters
                ]




            this.geocoder
                .geocode({ location: this.center })
                .then((response) => {
                    if (response.results[0]) {
                        console.log("entro 5");

                        console.log(response);

                        this.plusCode = response.results[0].formatted_address;
                        this.city = response.results[0].address_components[2].long_name;
                        this.autocomplete = response.results[1].formatted_address;

                    }
                })
                .catch((e) => console.log("Geocoder failed due to: " + e));



        },

        onSubmit(event) {
            event.preventDefault();
            // Lógica de envío del formulario
        },

        sendParametro(event) {
            event.preventDefault();
            let pruebas = { tokenAuth: this.tokenAuth, latitude: this.center.lat, longitude: this.center.lng, city: this.city };
            console.log(pruebas);

            this.marketSend.lat = ""
            this.marketSend.lng = ""

            this.marketSend.lat = this.center.lat
            this.marketSend.lng = this.center.lng

            this.showModal = true
        },

        async saveMarket(event) {
            event.preventDefault();
            console.log(this.marketSend);

            document.getElementById('btn_submit2').innerHTML = '<i class="fas fa-spinner fa-pulse"></i>  Registrando'


            this.$swal({
                title: "¿Seguro que deseas guardar la información?",
                icon: 'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(async (result) => {
                if (result.isConfirmed) {



                    try {

                        const response = await this.$https.post("api/v1/getAddMarcadores", this.marketSend, {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": this.tokenAuth,
                            }
                        });

                        document.getElementById('btn_submit2').innerHTML = 'Guardar'

                        console.log(response);

                        this.predefinedMarkers = []
                        this.showModal = false;

                        this.createMarkers()

                        if (response.data.code === 200) {
                            this.showSuccessToast('Grabación Éxitosa')
                        }

                    } catch (error) {
                        document.getElementById('btn_submit2').innerHTML = 'Guardar'
                        console.log(error);
                        //this.showErrorToast(error.response.data.message)
                        this.showErrorToast(error.response.data.message2)
                    }


                }
            });




        },

        cargarScript() {




            setTimeout(() => {
                if (!this.isGoogleMapsLoaded) {
                    this.isGoogleMapsLoaded = true;
                    this.instanceMap();
                }
            }, 2000);


        },
        // Crear marcadores en el mapa
        async createMarkers() {
            console.log("Creando marcadores");

            try {

                const response = await this.$https.post('/api/v1/getMarcadores', {}, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.tokenAuth,
                    },
                });


                this.cargarScript()

            

                if (response.data.code === 200) {

                    

                    let nuevosMarcadores = response.data.cobertura.map(marcador => {
                        return {
                            id: marcador.id,
                            ip: marcador.ip,
                            fech_registro: marcador.fech_registro,
                            detalle: marcador.detalle,
                            position: {
                                lat: parseFloat(marcador.latitude),
                                lng: parseFloat(marcador.longitude)
                            }
                        };
                    });

                   /*  console.log('nuevosMarcadores');
                    console.log(nuevosMarcadores); */

                    this.$eventBus.emit('showListadoIP', nuevosMarcadores);

                    this.predefinedMarkers = nuevosMarcadores

                }else if(response.data.code === 204){

                    this.predefinedMarkers = []

                    this.$eventBus.emit('showListadoIP', '');

                }

            } catch (error) {

                console.log('Viene al error');

                if (error.response.data.code === 300) {

                    this.$swal
                        .fire({
                            html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                            position: "center",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false, // Oculta el botón de confirmación
                            timer: 4000, // Duración en milisegundos (4 segundos)
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        })
                        .then(
                            () => {
                                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                console.log("closed by timer!!!!");

                                localStorage.clear();
                                location.reload(true);
                            },
                            (dismiss) => {
                                if (dismiss === "timer") {
                                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                    console.log("closed by timer!!!!");
                                    this.$swal.fire({
                                        title: "Finished!",
                                        icon: "success",
                                        showConfirmButton: false,
                                    });
                                }
                            }
                        );


                } else {
                    //this.$swal.close();
                    showErrorToast(error.response.data.messaje)
                }

            }


        },
    },
    computed: {
        // ... otras propiedades computadas
    },
    watch: {
        'marketSend.descrip': function (newObservacion, oldObservacion) {
            this.marketSend.descrip = newObservacion.toUpperCase();
        },
    },
    async mounted() {
        //this.cargarScript();
        this.createMarkers()

        this.$eventBus.on('showRegistroCobertura', () => {
            this.createMarkers()
        })
        
    },
};
</script>
<style lang="scss">
.modal-content {
    width: 800px !important;
}
</style>