<template>
    <div>
        <lista-niveles />
    </div>
</template>
  
<script>
/*eslint-disable */
import ListaNiveles from "./ListaNiveles.vue";

export default {
    name: "MiEquipo",
    data() {

        return {
            vendedorPrincipal: {
                nombre: 'Vendedor Principal',
                promotores: [
                    {
                        nombre: 'Promotor 1',
                        promotores: [
                            {
                                nombre: 'Promotor 1.1'
                            },
                            {
                                nombre: 'Promotor 1.2'
                            }
                        ]
                    },
                    {
                        nombre: 'Promotor 2',
                        promotores: [
                            {
                                nombre: 'Promotor 2.1'
                            },
                            {
                                nombre: 'Promotor 2.2'
                            }
                        ]
                    },
                ],
            },
            loading: true,
            isMobile: this.$isMobile,
        }

    },

    components: {
        ListaNiveles
        },

    activated() { },
    mounted() {
        if (this.isMobile) {
            this.$eventBus.emit("activeShowMenu");
        }
    },

    methods: {

    },
};
</script>
  
<style scoped>
.mapa-conceptual {
    display: flex;
    justify-content: center;
    align-items: center;
}

.persona {
    text-align: center;
    margin: 10px;
}

.conexiones {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.conexion {
    text-align: center;
    margin: 10px;
}

/* Puedes personalizar los estilos según tus preferencias */
</style>