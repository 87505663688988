<template>
    <div id="app">
      <div class="authentification">
        <h2>VueJS + Google Calendar Example</h2>
        Authentification
        <button v-if="!authorized" @click="handleAuthClick">Sign In</button>
        <button v-if="authorized" @click="handleSignoutClick">Sign Out</button>
      </div>
      <hr>
      <button v-if="authorized" @click="getData">Get Data</button>
      <div class="item-container" v-if="authorized && items">
        <pre v-html="items"></pre>
      </div>
    </div>
  </template>
  
  <script>
  /*eslint-disable */
  export default {
    data() {
      return {
        items: undefined,
        api: undefined,
        authorized: false,
      };
    },
    mounted() {
      // Cargar la API de Google después de que el componente ha sido montado.
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = this.handleClientLoad;
      document.head.appendChild(script);
    },
  
    methods: {
      handleClientLoad() {
        this.api = gapi;
        this.api.load('client:auth2', this.initClient);
      },
  
      initClient() {
        let vm = this;
  
        vm.api.client.init({
          apiKey: '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',
          clientId: '781587904774-sus3elclojsbcjd986l070lnkoog8og7.apps.googleusercontent.com',
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
          scope: 'https://www.googleapis.com/auth/calendar.readonly',
          redirect_uri: 'http://localhost/oauth/callback', // Aquí es donde defines tu URI de redireccionamiento
        }).then(() => {
          // Listen for sign-in state changes.
          vm.api.auth2.getAuthInstance().isSignedIn.listen(vm.updateSigninStatus);
          // Handle the initial sign-in state.
          vm.updateSigninStatus(vm.api.auth2.getAuthInstance().isSignedIn.get());
        });
      },
  
      handleAuthClick() {
        Promise.resolve(this.api.auth2.getAuthInstance().signIn())
          .then(_ => {
            this.authorized = true;
          });
      },
  
      handleSignoutClick() {
        Promise.resolve(this.api.auth2.getAuthInstance().signOut())
          .then(_ => {
            this.authorized = false;
          });
      },
  
      getData() {
        let vm = this;
  
        vm.api.client.calendar.events
          .list({
            calendarId: 'primary',
            timeMin: new Date().toISOString(),
            showDeleted: false,
            singleEvents: true,
            maxResults: 10,
            orderBy: 'startTime',
          })
          .then((response) => {
            vm.items = this.syntaxHighlight(response.result.items);
          });
      },
  
      updateSigninStatus(isSignedIn) {
        this.authorized = isSignedIn;
      },
  
      syntaxHighlight(json) {
        // Resto de la función syntaxHighlight
      },
    },
  };
  </script>
  