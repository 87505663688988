<template>
  <div class="mt-2" style="padding-left: 10px; padding-right: 10px; padding-left: 10px;">

    <VueFinalModal v-model="showModal" :esc-to-close="true" @closed="closed" class="modal-container"
      content-class="modal-content">
      <div class="modal__content">
        <div class="row">

          <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11">
          </div>

          <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1 ">
            <i class="fa fa-times-circle" style="cursor: pointer;" @click="closed"></i>
          </div>

        </div>

        <form-venta :prueba="abcd" :bandera="ban" />

      </div>
    </VueFinalModal>

    <VueFinalModal v-model="showModal2" :esc-to-close="true" @closed="closed2" class="modal-container"
      content-class="modal-content" header-class="modal-header">


      <div class="modal__content">

        
        <div class="row"> 

          

          <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11">  

            </div>

            <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1 " @click="closed2">  


            <i class="fas fa-times-square close_modal"></i>

            </div>


          </div>


        <retiro-cash v-if="showModal2" :user-id="userId" :cash-money="balance" />



      </div>
      
    </VueFinalModal>


    <div class="row">
      <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
        <swiper :slides-per-view="3" :space-between="15" :centered-slides="false" :navigation="false" :pagination="false"
          :breakpoints="{
            200: {
              slidesPerView: 1.1,
            },
            400: {
              slidesPerView: 1.1,
            },
            550: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 3.5,
            },
            1024: {
              slidesPerView: 2.5,
            },
            1200: {
              slidesPerView: 4,
            },
          }">
          <swiper-slide>
            <saldos />
          </swiper-slide>
          <swiper-slide>
            <referidos-primer-nivel />
          </swiper-slide>
          <swiper-slide>
            <referidos-segundo-nivel />
          </swiper-slide>
          <!-- <swiper-slide>
            <referidos-tercer-nivel />
          </swiper-slide> -->
        </swiper>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-lg-4 col-md-4 col-xl-3" align="center"
        style="padding-top:10px; overflow: auto; margin: 0 auto;">
        <link-referidos />
      </div>

      <div class="col-12 col-sm-12 col-lg-8 col-md-8 col-xl-9" style="margin-top:10px; overflow: auto;">



        <!-- <div class="card" style="margin-bottom:10px; background: #001fd0;"> -->
          <div class="card" style="margin-bottom:10px;">
          <div class="card-body">
            <h6 style="font-size:14px;  font-weight: bold;margin-bottom: 20px; line-height: 15px;text-align:center;">¡Inscribe a tu referido! Ganarás $10.00 por cada uno. </h6>
            <div align="center">
              <button @click="registerReferido" class="btn btn-primary btn-lg"
                style="background-color: #3ee000 !important;color: #001fd0 !important;padding: 15px 25px;">Registrar
                Referidos </button>

              <hr class="horizontal dark" style="margin-bottom: 10px;" />

            </div>
          </div>
        </div>







        <listado-ventas />
      </div>





      <div
        class="float_whatsapp d-none d-sm-none d-md-inline d-lg-inline d-xl-inline col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">

        <a :href="textAyuda" target="_blank">

          <div style=" justify-content: space-around;display: flex;">

            <svg width="30" class="my-float" style="    margin-top: 7px;margin-left: 10px;" height="30"
              viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentcolor">
              <path
                d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg>

            <p class="dudas_txt">¿Necesitas ayuda? Contácta con soporte.</p>


          </div>




        </a>
      </div>


      <div class="d-inline d-sm-inline d-md-none d-lg-none d-xl-none col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">

        <a class="float" :href="textAyuda" target="_blank">


          <svg width="30" class="my-float" style="margin-top: 14px;" height="30" viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg" fill="currentcolor">
            <path
              d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
          </svg>







        </a>
      </div>








    </div>


  </div>
</template>
<script>
/*eslint-disable */
import LinkReferidos from "./LinkReferidos.vue";
import Saldos from "./Saldos.vue";
import ReferidosPrimerNivel from "./ReferidosPrimerNivel.vue";
import ReferidosSegundoNivel from "./ReferidosSegundoNivel.vue";
import ReferidosTercerNivel from "./ReferidosTercerNivel.vue";
import ListadoVentas from "./ListadoVentas.vue";
import FormVenta from "../FormVenta.vue";
import RetiroCash from './RetiroCash.vue';
import { VueFinalModal } from 'vue-final-modal';
import 'vue-final-modal/style.css';




import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  name: "dashboard-default",

  data() {

    //const datos_json = (this.$store.state.user)
    const displayName = JSON.parse(localStorage.getItem('userData')).displayName
    let datos = JSON.parse(this.$store.state.user);
    
    return {
      showModal: false,
      showModal2: false,
      isMobile: this.$isMobile,
      loading: true,
      balance: '',
      tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
      abcd: [],
      ban: '',
      userId: datos.code,//JSON.parse(localStorage.getItem('userData')).code,
      componentKeyFormVenta: 500,
      textAyuda: "https://api.whatsapp.com/send?phone=593964261109&text=Hola,%20soy%20" + displayName + "%20y%20deseo%20obtener%20ayuda%20en%20JUBONESNET"

    }
  },
  components: {
    Saldos,
    RetiroCash,
    ReferidosPrimerNivel,
    ReferidosSegundoNivel,
    ReferidosTercerNivel,
    ListadoVentas,
    LinkReferidos,
    VueFinalModal,
    FormVenta,
    Swiper,
    SwiperSlide
  },

  methods: {

    registerReferido() {

      this.$swal({
                title: "¿Informaste a tu referido sobre el servicio de Internet antes de registrarlo?",
                icon: 'question',                
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Si le informé',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
                iconColor: '#87adbd' //color del icono del info
            }).then((result) => {
                if (result.isConfirmed) {

                  this.ban = true
                  this.showModal = true;

                }
            });



    },
    closed() {


      this.showModal = false;

    },

    closed2(){
      
      this.showModal2=false;
      
    },

    async cargaCantidadReferidos() {

      const response = await this.$https.post('/api/v1/getlistCantidadNiveles', {
        idUser: this.userId,
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.tokenAuth,
          // Otros encabezados si es necesario
        },
      });

      //console.log('Cant Referidos');
      //console.log(response);

      if (response.data.code === 200) {
        this.$eventBus.emit('showCantReferidos', response.data);
      }








    }
  },

  created() {

  },

  beforeUnmount() {


  },
  activated() {




  },
  deactivated() {
    console.log('About has been deactivated')
  },



  mounted() {


    const userData = JSON.parse(localStorage.getItem('userData'));

    if (!userData || !userData.dni || userData.dni.length === 0) {
      localStorage.clear(); //limpio sesioens

      this.$router.push({ name: 'Signin' })
    }



    if (this.isMobile) {
      this.$eventBus.emit('activeShowMenu')
    }

    this.cargaCantidadReferidos()

    //Aqui me actualiza la lista
    this.$eventBus.emit('showCargaLista')
    this.$eventBus.emit('showCargaSaldos')

    this.$eventBus.on('showPedro', () => {
      this.showModal = false;
      this.$eventBus.emit('showCargaLista')
    });

    this.$eventBus.on('showModalRetiro', (datos) => {
      //console.log('cantidad de retiro: ' + datos);
      this.balance = datos
      this.showModal2 = true;
    });

    this.$eventBus.on('showModalRetiro2', () => {      
      this.showModal2 = false;
    });    

    this.$eventBus.on('envioParametroAlDashboard', () => {
      this.cargaCantidadReferidos()
    });



  },
};
</script>

<style></style>