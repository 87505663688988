<template>
  <VueFinalModal v-model="showModal" :esc-to-close="true" class="modal-container" content-class="modal-content">

    <span class="modal__title">Términos y condiciones de uso</span>
    <div class="modal__content">

      <p style="font-size:12px;text-align: justify;">
        En mi calidad de usuario (en adelante “el usuario” o “usuario”) de esta plataforma (en adelante plataforma
        JUBONESNET) de titularidad de JUBONESNET, reconozco expresamente que al utilizarla debo sujetarme en todo
        momento a lo aquí establecido. Al acceder o utilizar esta plataforma web, <b>acepto expresa, libre y
          voluntariamente los siguientes Términos y Condiciones de Uso.</b>
      </p>
      <p style="font-size:12px;text-align: justify;">
        Reconozco y acepto que la plataforma JUBONESNET en la persona de su administrador o titular, se reserva el derecho de
        negarme el acceso a la misma en cualquier caso que considere apropiado, en particular si yo como usuario:
      </p>

      <p style="font-size:12px;text-align: justify;">
      <ol>
        <li>Proporciono <b>datos falsos</b></li>
        <li><b>Incumplo</b> estos Términos y Condiciones de Uso de cualquier forma; y,</li>
        <li>Incumplo <b>cualquier normativa legal</b> aplicable respecto del acceso o el uso de la presente plataforma.
        </li>

      </ol>
      </p>

      <p style="font-size:12px;text-align: justify;">

        <b>Datos Personales:</b> Autorizo expresamente a JUBONESNET a utilizar mis datos personales entregados o
        generados por medio de mi utilización de la plataforma JUBONESNET. Esta autorización incluye los siguientes usos:
      </p>

      <p style="font-size:12px;text-align: justify;">
      <ul>
        <li>Acceso a datos; </li>
        <li>Consulta de buró crediticio; </li>
      </ul>
      </p>


    </div>
    <div class="modal__action">

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

          <button type="button" class="btn btn-primary" @click="confirmar" style="width: 100%;">
            Confirmar
          </button>

        </div>


      </div>

    </div>
  </VueFinalModal>

  <h5 class="mt-3 mb-5" style="text-align: center;margin-bottom: 15px; font-weight: bold;">Inicia sesión para ver más!</h5>

  <p class="mt-3" style="text-align: center; margin-bottom: 15px; font-size:17px">
    Acceso gratuito a la plataforma,<br>
    en la cual podrás referir servicio de Internet y ganar.
  </p>

  <br>

  <div class="text-center" style="margin-bottom:15px">
    <argon-button class="mt-1" id="btn_ingreso" :disabled="isActiveGoogle" @click="autenticacion('google')" variant="gradient"
       style="width: 100%; background: #002EC0!important;" size="lg">
      <i class="fab fa-google"  style="margin-right: 5px;"></i> {{ textoButton }} con Gmail
    </argon-button>
  </div>
  <br>

</template>
  
  
<script>
/*eslint-disable */
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import 'vue-final-modal/style.css'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/app-check'

export default {
  name: "formulario",
  components: {
    ArgonButton,
    VueFinalModal
  },
  props: ['promoCode', 'textoButton', 'isLogin'],
  data() {
    return {
      token: '',
      showTerminos: false,
      showModal: false,
      isActiveGoogle: false,
      recaptchaVerifier: null,
      recaptchaWidgetId: null,
      appCheck: null,
      user: {
        email: '',
        password: '',
      },
    }
  },

  computed: {


  },
  async mounted() {

    

    if (this.promoCode) {
      /* console.log('Soy diferente a undefined y diferente de vacío');
      console.log('Esto es para referir: ' + this.promoCode); */
    }

    console.log('isLogin: ' + this.isLogin)

  /*  firebase.auth().useDeviceLanguage();

    this.appCheck = firebase.appCheck()


     this.appCheck.activate(
      '6LfqRiMpAAAAAI9ySgpoHlJUYn0SuagP4QLfj67b',

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      true,
    ) 


    let tokenAux = await this.appCheck.getToken();

    this.token = tokenAux.token;

     console.log('Token firebase');

    console.log(this.token); 




    this.appCheck.setTokenAutoRefreshEnabled(true)

    this.recaptchaVerifier= new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                                        'size': 'invisible',
                                        'callback': (response) => {
                                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                                        // this.login();
                                    
                                        }
                                    }); */



  },
  methods: {

/*    async pruebaApp(){
        const response = await this.$https.post("api/v1/consultaAppCheck", {dni: ''}, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": this.token
                        }
                    });
                    console.log('respondo del backend');
                    console.log(response);

    }, */

    showSuccessToast(message) {
      this.$toast.success(message, {
        position: "top-right",
        max: 10
      });
    },

    showErrorToast(message) {
      this.$toast.error(message, {
        position: "top-right",
        max: 10
      });
    },

    confirmar() {

      console.log('he confirmado');
      //cierra la ventana de terminos

      this.showModal = false;
      this.$router.push({ name: 'Perfil' })

    },

    async autenticacion(type) {

      //console.log('Ingreso a Gmail');

      let provider = ''

      document.getElementById('btn_ingreso').innerHTML = '<i class="fas fa-spinner fa-pulse"></i> Espere...'

      if (type == 'google') {
        provider = new firebase.auth.GoogleAuthProvider()
        this.isActiveGoogle = true

      }

      firebase.auth()
        .signInWithPopup(provider)
        .then(async result => {
          const { user } = result

          let datos = {
            displayName: user.providerData[0].displayName,
            email: user.providerData[0].email,
            photoURL: user.providerData[0].photoURL,
            promoCode: this.promoCode
          }

          const { data } = await this.$https.post('api/v1/getLogin', datos);
          //console.log(data);

          if (data.code === 204) {
            //console.log(data.message);
            let userData = { email: user.providerData[0].email }
            this.showModal = true
            localStorage.setItem("authorization", `${data.token}`);
            localStorage.setItem('userData', JSON.stringify(userData));

            //Se ha registrado anteriormente pero no ha actualizado perfil

          }

          if (data.code === 201) {
            //console.log(data.message);
            //Primer registro y se dirige a que actualice el perfil
            let userData = { email: user.providerData[0].email }
            localStorage.setItem("authorization", `${data.token}`);
            localStorage.setItem('userData', JSON.stringify(userData));
            this.showModal = true


          }

          if (data.code === 200) {
            console.log('Me ingresa al 200 y ya ha actualizado el perfil');
            //console.log(data.datos.dni);
            const rol = data.datos.rol
            let sessionType = this.$encryptBackoffice.encrypt(rol);

            let userData = {
              dni: data.datos.dni,
              displayName: data.datos.displayName,
              user: data.datos.email,
              phone: data.datos.phone,
              code: data.datos.code,
              photo: data.datos.photo
            }


            this.$store.state.user= JSON.stringify(userData);

            this.$https.post('api/v1/notificacionSesion', {displayName: userData.displayName, movil: userData.phone });

            localStorage.setItem("authorization", `${data.datos.token}`);
            localStorage.setItem('userData', JSON.stringify(userData));
            localStorage.setItem("session", sessionType); //envio la session encryptada - promotor - administrador - callcenter
            this.$router.push({ name: 'Dashboard' })

          }











        }).catch(error => {

          console.log(error);
          console.log('error de sesion de google');

          if (error.code === 'auth/user-disabled') {

            this.showErrorToast('La cuenta de usuario ha sido deshabilitada por un administrador.')

          } else {

            this.showErrorToast(error.message)

          }

        })


    }



  },
  created() {


  },


  activated() {
    this.user.password = ''
  },

  beforeUnmount() {

  },
};
</script>
  
<style scoped>
.bg-gradient-success {
  background-image: linear-gradient(310deg, #5e02ca 0%, #8100d3 100%);

}



.m-input.--md .m-input-input,
.m-input.--md .m-input-label {
  font-size: 12px !important;
  line-height: 1.5rem;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  cursor: not-allowed;
  pointer-events: auto;
}
</style>
  