<template>
  <div>


    <!-- <PWAPrompt v-if="shown" :install-event="installEvent"/> -->

    <div class="container-fluid">

      <!-- Esto es movil -->
      <div v-if="isMobile" class="row vh-100" style="    min-height: calc(var(--vh, 1vh)*100);margin: 0px;">

        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex d-sm-flex d-md-flex d-md-flex d-lg-flex d-xl-flex justify-content-center text-center align-items-center">
          <div class="row align-items-center">
            <div class="col-12 col-md-12 col-lg-12">
              <span class="text-primary">
                <img src="../../src/assets/img/empresa/logoJubonesNet.png" style="width: 300px" />
              </span>

               <!-- <p class="mb-0 text-center" style="font-size:16px;margin-top:10px">Internet por fibra Óptica.</p> --> 


              <formulario :is-login="isLogin" :texto-button="textoButton" />

              <!-- <p class="mt-3" style="margin-top: 10px; margin-bottom: 0; font-size: 12px;">
                <span>¿Aún no te has registrado?</span>
                <router-link to="/Registrarse/Perfil" style="font-size: 12px;"><b> Registrate!</b></router-link>
              </p> -->


              <hr class="horizontal dark" style="margin-top:35px" />

              <a target="_blank" title="Facebook de EQPay" href="https://www.facebook.com/"> <i
                  class="fab fa-facebook-f redes" aria-hidden="true"></i> </a>
              <a target="_blank" title="Instagram de EQPay" href="https://www.instagram.com/"> <i
                  class="fab fa-instagram redes" aria-hidden="true"></i> </a>
              <a target="_blank" title="Email de EQPay" href="#">
                <i class="fas fa-envelope redes" aria-hidden="true"></i> </a>


              <a target="_blank" title="Linea de WhatSivo" href="https://wa.me/593964261109"> <i
                  class="fab fa-whatsapp redes" aria-hidden="true"></i> </a>


                  <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> <a href="#" target="_blank">Términos y
                  condiciones</a> - <a
                  href="#" target="_blank">Reclamos</a></p>

              <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }}<a
                  href="#" target="_blank">- JubonesNet</a></p>




            </div>
          </div>
        </div>






      </div>

      <!-- esto es desktop -->
      <div v-else class="row vh-100" style="    min-height: calc(var(--vh, 1vh)*100);margin: 0px;">

        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex d-sm-flex d-md-flex d-md-flex d-lg-flex d-xl-flex justify-content-center text-center align-items-center">
          <div class="row align-items-center">
            <div class="col-12 col-md-12 col-lg-12">
              <span class="text-primary">
                <img src="../../src/assets/img/empresa/logoJubonesNet.png" style="width: 300px" />
              </span>              

              <!-- <p class="mb-0 text-center" style="font-size:16px;margin-top:10px">Internet por fibra Óptica.</p> -->

              
              

              <formulario :is-login="isLogin" :texto-button="textoButton" />


              <!-- <p class="mt-3" style="margin-top: 10px; margin-bottom: 0; font-size: 12px;">
                <span>¿Aún no te has registrado?</span>
                <router-link to="/Registrarse/Perfil" style="font-size: 12px;"><b> Registrate!</b></router-link>
              </p> -->



              <hr class="horizontal dark" style="margin-top:35px" />

              <a target="_blank" title="" href="https://www.facebook.com/"> <i
                  class="fab fa-facebook-f redes" aria-hidden="true"></i> </a>
              <a target="_blank" title="" href="https://www.instagram.com/"> <i
                  class="fab fa-instagram redes" aria-hidden="true"></i> </a>
              <a target="_blank" title="" href="#">
                <i class="fas fa-envelope redes" aria-hidden="true"></i> </a>


              <a target="_blank" title="" href="#"> <i
                  class="fab fa-whatsapp redes" aria-hidden="true"></i> </a>


              <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> <a href="#" target="_blank">Términos y
                  condiciones</a> - <a
                  href="#" target="_blank">Reclamos</a></p>

              <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }} <a
                  href="" target="_blank">- JubonesNet</a></p>




            </div>
          </div>
        </div>


      </div>

    </div>

  </div>
</template>


<script>
/*eslint-disable */

import Formulario from "./Formulario.vue";
//import PWAPrompt from './PWAPrompt.vue'

export default {
  name: "signin",
  components: {

    Formulario,
    //PWAPrompt
  },
  data() {
    return {
      isLogin: true,
      textoButton: "Inicia sesión",
      year: new Date().getFullYear(),
      isMobile: this.$isMobile,
      photo: "/img/landing6.png",
      shown: false,
      installEvent: null,
    }
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },
  
  methods: {


  },

  mounted() {
    

    document.body.classList.remove('dark-version')
    document.title = 'JubonesNet - Inicio de sesión';

    let userData = JSON.parse(localStorage.getItem("userData"));
    let revisaSesion = userData ? userData.user : null;

    console.log('revisaSesion: ' + revisaSesion);

    if (revisaSesion != "") {
      this.$router.push({ name: 'Dashboard' })
    }

  },
  created() {


  },
  beforeUnmount() {

  },
};
</script>

<style scoped>
.container-fluid {
  padding: 0px;
  margin: 0px;
}
</style>