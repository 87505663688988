<template>
    <div class="card" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
                    style="margin-bottom:10px;  justify-content: space-between;display: flex;">

                    <h5 class="card-title mb-0 text-uppercase font-weight-bold" style="font-size: 12px;">TU EQUIPO SEGUNDO NIVEL
                    </h5>
                    <i v-if="!loading" @click="refresh" style="cursor:pointer;font-size: 6px"
                        class="fas fa-sync text-sm opacity-10"></i>
                </div>

            </div>

            <div class="row" style="text-align:center">
                <div class="col-xl-8 col-lg-8 col-md-8 col-8 col-sm-8" style="margin: 0 auto;">
                    <Skeletor v-if="loading" :shimmer="true" height="38" width="100%" />
                    <p v-else class="font-weight-bolder priceCard" style="text-align:center"><span
                            style="font-size:23px">{{ cantidadSegundoNivel }}</span></p>
                </div>
            </div>


        </div>
    </div>
</template>
<script>

/* eslint-disable */

export default {
    name: "saldo-default",
    components: {





    },

    data() {


        return {
            loading: true,
            cantidadSegundoNivel: 0

        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

        this.$eventBus.on('showCantReferidos', (parametro) => {
            this.loading = false
            //console.log('Nivel2');
            //console.log(parametro.cantSegundoNivel);
            this.cantidadSegundoNivel = parametro.cantSegundoNivel;
        });


    },
    methods: {

        refresh() {

            this.$eventBus.emit('envioParametroAlDashboard');

        }
    }

}
</script>