class PromSleep {
    generateTiempo(ms){
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    hola(){
        console.log('hihihihihi');
    }
}


export default PromSleep;