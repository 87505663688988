<template>
    <div class="container-fluid">

        <div class="row" style="width: 100%;margin:0px">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark"
                    style="height: 200px;display:block;    margin: 0px !important">

                    <div style=" justify-content: space-between;display: flex;">

                        <img src="../assets/img/empresa/logoJubonesNet-white.png" style="width: 125px;" />

                        <button @click="exit"
                            style="background-color: rgb(254, 250, 250)!important; color: #002EC0; padding: 7px 20px; border: none; border-radius: 50px; ">
                            <i class="fa fa-sign-out text-sm " style="font-size: 6px"></i> <span
                                style="color:#002EC0; font-size: 12px; font-weight: bold">Cerrar Sesión</span>
                        </button>
                    </div>

                    <p
                        style="text-align: center; color:white; font-weight: bold;font-size:23px; margin-bottom: 0px;margin-top: 30px;    line-height: 28px;">
                        Un paso más... ¡Necesitamos actualizar tus datos!</p>


                </nav>

            </div>


            <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3">

            </div>

            <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">

                <div class="card" style=" margin-top: -55px; ">

                    <div class="card-body" style="padding:20px !important">
                        <!-- contenido de la tarjeta -->
                        <!-- contenido de la tarjeta -->



                        <div class="row">

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom:7px">

                                <form role="form" autocomplete="off" @submit.prevent="onSubmitSearch">
                                    <div class="row justify-content-center">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
                                            <label for="example-text-input" class="form-control-label"
                                                style="font-weight: bold; font-size: 12px;">
                                                Ingrese su número de cédula (*):
                                            </label>
                                            <div class="input-group mb-3">
                                                <input type="number" required :readonly="isDisabled3" class="form-control"
                                                    ref="myDni" placeholder="Número de cédula" v-model="user.cedula"
                                                    aria-label="Número de cédula" aria-describedby="button-addon2">
                                                <button class="btn-outline-secondary btn2" :disabled="isDisabled3"
                                                    type="submit">
                                                    <i class="fa fa-search" aria-hidden="true"
                                                        style="margin-right: 5px;"></i>
                                                    <b>Buscar</b>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>


                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <form role="form" autocomplete="off" @submit.prevent="getSavePerfil">

                                    <div class="row" v-if="mostrar">

                                        <div class="col-lg-8 offset-lg-2 " style="margin-bottom:10px">
                                            <label for="example-text-input"
                                                class="form-control-label align-middle">Nombres</label>
                                            <input class="form-control col-lg-12" type="text" autocomplete="off" readonly
                                                v-model="user.nombres" />
                                        </div>

                                        <div class="col-md-6" style="margin-bottom:10px">
                                            <label for="example-text-input" class="form-control-label">Correo</label>
                                            <input class="form-control" type="email" required readonly
                                                v-model="user.email" />
                                        </div>
                                        <div class="col-md-6" style="margin-bottom:10px">
                                            <label for="example-text-input" class="form-control-label">F. Nacimiento</label>
                                            <input class="form-control" type="text" required readonly
                                                v-model="user.fecha" />

                                        </div>


                                        <div class="row justify-content-center mt-3">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-9">
                                                <div align="center">
                                                    <MazPhoneNumberInput type="tel" default-country-code="EC"
                                                        style="width: 100%;" no-flags no-example required autocomplete="off"
                                                        show-code-on-list size="sm" v-model="phoneNumber" color="info"
                                                        @update="results = $event" :translations="{
                                                            countrySelector: {
                                                                placeholder: 'Código',
                                                                error: 'Elija un país',
                                                            },
                                                            phoneInput: {
                                                                placeholder: 'Nro. celular',
                                                                example: 'Ejemplo:',
                                                            },
                                                        }" />
                                                </div>
                                            </div>
                                        </div>



                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

                                            <hr class="horizontal dark" />


                                            <button id="btn_update" type="submit" :disabled="isDisabled2"
                                                class="btn btn-primary" style="width: 100%;">
                                                Actualizar Perfil </button>
                                        </div>





                                    </div>

                                </form>





                            </div>







                        </div>




                    </div>
                </div>

            </div>

            <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3">

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-center align-items-center justify-content-xl-between"
                style="color:black !important;margin-top: 15px; ">
                <hr class="horizontal dark" />


                <a target="_blank" title="Facebook de JubonesNet"
                    href="https://www.facebook.com/profile.php?id=100090370913056"> <i class="fab fa-facebook-f redes"
                        aria-hidden="true"></i> </a>
                <a target="_blank" title="Instagram de JubonesNet" href="#"> <i class="fab fa-instagram redes"
                        aria-hidden="true"></i> </a>
                <a target="_blank" title="Email de JubonesNet" href="#"> <i class="fas fa-envelope redes"
                        aria-hidden="true"></i> </a>
                <a target="_blank" title="Tiktok de JubonesNet" href="#"> <svg style="margin-bottom: 10px;"
                        xmlns="http://www.w3.org/2000/svg" fill="#7d0c7e" widht="10" height="22" viewBox="0 0 448 512">
                        <path
                            d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                    </svg></a>

                <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> <a href="#" target="_blank">Términos y
                        condiciones</a> - <a href="#" target="_blank">Política de privacidad</a> - <a href="#"
                        target="_blank">Aviso legal</a></p>
                <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }} <a
                        href="#" target="_blank">- JubonesNet</a></p>

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

            </div>

        </div>

    </div>
</template>
  
  
<script>
/*eslint-disable */


import myMixin from '../mixin.js'


export default {
    name: "editarPerfil",
    data() {

        const { logout } = myMixin()
        //const datos_json = JSON.parse(this.$store.state.user)

        //const datos_json = JSON.parse(this.$store.state.user);


        return {
            logout: logout,
            mostrar: false,
            isDisabled: false,
            isDisabled2: false,
            isDisabled3: false,
            year: new Date().getFullYear(),
            contieneCedula: '',
            phoneNumber: null,
            countryCode: '',
            results: null,
            isActive: null,
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            user: {
                nombres: '',
                cedula: '',
                email: JSON.parse(localStorage.getItem('userData')).email,
                fecha: '',
                movil: '',
                datosGeneral: ''
            }
        }
    },
    watch: {
        results(newValue) {
            if (newValue.isValid) {
                this.isActive = true

                this.phoneNumber = newValue.e164
                this.user.movil = this.phoneNumber
                this.countryCode = newValue.countryCode

                this.isDisabled2 = false

            } else {

                this.isActive = false

                this.countryCode = ''
                this.code = ''

                this.isDisabled2 = true

            }
        },
    },
    methods: {

        exit() {

            localStorage.clear(); //limpio sesioens

            this.$router.push({ name: 'Signin' })
            


            /* setInterval(() => {
                location.reload(true);  
            }, 1000); */


        },

        onSubmitSearch(event) {
            event.preventDefault();

            this.getDatos();

        },
        getDatos() {


            let cedula = ''

            if ((String(this.user.cedula).length) === 9) {

                cedula = '0' + this.user.cedula
                this.user.cedula = cedula
                this.consultar()
            } else if ((String(this.user.cedula).length) === 10) {


                this.consultar()
            } else {

                this.$swal.fire({
                    title: "Cédula incorrecta!",
                    icon: 'warning',
                    width: '400px',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                });

                this.user.cedula = "";

            }

        },
        async consultar(e) {




            this.$swal({

                html:
                    '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'top',
            });

            //const token = 'Bearer ' + this.tokenAuth;
            try {



                const data = {
                    dni: this.user.cedula,
                };

                const response = await this.$https.post("api/v1/getConsultaCedula", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.tokenAuth,
                    }
                });

                //console.log(response);

                if (response.data.code === 200) {

                    this.$swal.close();

                    this.isDisabled3 = true;
                    this.mostrar = true;
                    this.user.datosGeneral = response.data.datos
                    this.user.nombres = response.data.datos.nombres;
                    this.user.fecha = response.data.datos.fecha_nacimiento;
                    /* const fechaInput = response.data.datos.FechaNacimiento;

                    // Validar la fecha antes de formatearla
                    const fechaParts = fechaInput.split('/');
                    const fechaObj = isValidDate(fechaParts[2], fechaParts[1], fechaParts[0])
                        ? new Date(fechaParts[2], fechaParts[1] - 1, fechaParts[0])
                        : null;

                    if (fechaObj) {
                        const fechaFormateada = fechaObj.toLocaleDateString('en-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        });
                        this.user.fechaNac = fechaFormateada;
                    }

                    let sexo = response.data.datos.Sexo;

                    if (sexo === 'M') {
                        this.user.sexo = 'Masculino';
                    } else {
                        this.user.sexo = 'Femenino';
                    }

                    this.user.estadoCivil = response.data.datos.EstadoCivil;


                    // Función para validar la fecha
                    function isValidDate(year, month, day) {
                        const testDate = new Date(year, month - 1, day);
                        return (
                            testDate.getFullYear() === Number(year) &&
                            testDate.getMonth() === month - 1 &&
                            testDate.getDate() === Number(day)
                        );
                    } */





                } else if (response.data.code === 204) {

                    this.$swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error', // 'warning', 'error', 'info', 'success', etc.
                        showConfirmButton: false,
                    });


                }





            } catch (error) {
                this.$swal.close()
                console.log('Viene al error');

                if (error.response.data.code === 300) {

                    console.log(error.response.data.message);

                    this.$swal
                        .fire({
                            html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                            position: "center",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false, // Oculta el botón de confirmación
                            timer: 4000, // Duración en milisegundos (4 segundos)
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        })
                        .then(
                            () => {
                                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                console.log("closed by timer!!!!");

                                localStorage.clear();
                                location.reload(true);

                            },
                            (dismiss) => {
                                if (dismiss === "timer") {
                                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                    console.log("closed by timer!!!!");
                                    this.$swal.fire({
                                        title: "Finished!",
                                        icon: "success",
                                        showConfirmButton: false,
                                    });
                                }
                            }
                        );


                } else {

                    this.$swal.fire({
                        title: "Error!",
                        icon: "error",
                        message: error.message,
                        showConfirmButton: false,
                    });
                }
            }

            //this.$swal.close()




        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "top-right", // Cambiado de "bottom-right" a "top-right"
                max: 10
            });
        },

        async getSavePerfil(e) {

            e.preventDefault()

            const response = await this.$https.post("api/v1/getUpdatePerfil", this.user, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.tokenAuth,
                }
            });


            //console.log(response);

            if (response.data.code === 400) {
                return this.showErrorToast(response.data.message)
            }

            if (response.data.code === 200) {

                const rol = response.data.rol
                const photo = response.data.photo
                let sessionType = this.$encryptBackoffice.encrypt(rol);
                let userData = { dni: this.user.cedula, displayName: this.user.nombres, user: this.user.email, phone: this.user.movil, photo: photo, code: response.data.datos }
                this.$store.state.user= JSON.stringify(userData);
                localStorage.setItem('userData', JSON.stringify(userData));
                localStorage.setItem("session", sessionType); //envio la session encryptada - promotor - administrador - callcenter
                this.$router.push({ name: 'Dashboard' })
                
                //Enviar una bienvenida
                this.$https.post('api/v1/getBienvenidaWs', {displayName: userData.displayName, number: userData.phone });

            }




        },

    },
    computed: {

    },
    mounted() {

        //console.log(this.userId);
        this.$refs.myDni.focus();

    },
    created() {


    },

    activated() {

        const userDataString = localStorage.getItem('userData');

if (userDataString !== null && userDataString !== undefined) {
    // El valor existe, puedes procesarlo
    const userData = JSON.parse(userDataString);

    // Hacer algo con userData
    // ...

} else {
    console.error('No se encontró el valor en localStorage para "userData".');
    // Puedes manejar la ausencia del valor según tus necesidades
    // Por ejemplo, redirigir a la página de inicio de sesión
    this.$router.push({ name: 'Signin' });
}



    },

    beforeUnmount() {

    },
};
</script>
  
<style scoped>
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.container-fluid {
    padding: 0px;
    margin: 0px;
}

.bg-dark {
    background: linear-gradient(87deg, #1f1f93, #1f1f93) !important;
    height: 160px;
}
</style>
  