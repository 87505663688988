
<template>
 
    <div>


        <div class="row">


            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="card">
      

                    <div class="card-body">

                        <p style="font-size:14px;text-align: justify;">
                          Tras el registro y la Suscripción exitoso al Servicio, usted acepta estos términos el <b>”Acuerdo“</b>. Al acceder o utilizar los Servicios ofrecidos por WhatSivo, usted confirma que ha leído, comprende y acepta este Acuerdo. 
        </p>
        <p style="font-size:14px;text-align: justify;">
          Reconozco y acepto que la plataforma WHATSIVO, representada por su administrador o titular, se reserva el derecho de denegarme el acceso a la misma en cualquier momento que considere apropiado, en particular; si como usuario: 
        </p>

        <p style="font-size:14px;text-align: justify;">
          <ol>
            <li style="font-size:14px;">Proporciono <b>datos falsos.</b></li>
            <li style="font-size:14px;">Realizo SPAM <b>sin aprobación del cliente.</b></li>
            <li style="font-size:14px;"><b>Incumplo</b> estos Términos y Condiciones de Uso de cualquier forma; y,</li>
            <li style="font-size:14px;">Incumplo <b>cualquier normativa legal</b> aplicable respecto del acceso o el uso de la presente plataforma. </li>
      
          </ol>
        </p>

        <p style="font-size:14px;text-align: justify;">
          Acepta libre, voluntaria y expresamente que soy el único responsable del contenido que cargue, publique, envíe por WhatsApp, transmita o de cualquier forma ponga a disposición a través de esta plataforma.
        </p>

        <p style="font-size:14px;text-align: justify;">
     
          Adicionalmente me obligo expresa, libre y voluntariamente a <b>no utilizar la presente plataforma en forma alguna que sirva directa o indirectamente para:</b>
           </p>

           <p style="font-size:14px;text-align: justify;">
          <ul>
            <li style="font-size:14px;">Dañar a otras personas de cualquier forma; </li>
            <li style="font-size:14px;">Realizar declaraciones falsas; </li>
            <li style="font-size:14px;">Difundir de cualquier forma contenido que viole un derecho de propiedad intelectual de terceros, incluyendo pero no limitado a marcas, derechos de autor, secretos empresariales, patentes y diseños industriales; y, </li>
            <li style="font-size:14px;">Violar cualquier ley o norma jurídica nacional o internacional. </li>
          </ul>
        </p>

        <p style="font-size:14px;text-align: justify;">
     
          Reconozco y acepto que la plataforma WHATSIVO, representada por su administrador o titular, se reserva el derecho de modificar a su discreción los términos y condiciones actuales, informándome oportunamente de dichas modificaciones y siempre de acuerdo con la legislación ecuatoriana.
  
        </p>

        <p style="font-size:14px;text-align: justify;">
     
          <b>Datos Personales:</b> Autorizo expresamente a WHATSIVO a utilizar mis datos personales entregados o generados por medio de mi utilización de la plataforma WHATSIVO. Esta autorización incluye los siguientes usos:
         </p>

         <p style="font-size:14px;text-align: justify;">
          <ul>
            <li style="font-size:14px;">Acceso a datos; </li>
            <!-- <li style="font-size:14px;">Consulta de buró crediticio; </li> -->
        </ul>
        </p>


                    </div>
                </div>
            </div>



            </div>


</div>
</template>

<script>
/*eslint-disable */


export default {
    name: "Terminos",
    data() {

    

        return {
          isMobile: this.$isMobile,
        }
    },
    activated() {
        console.log('2) About has been activated')
  
          if (this.isMobile){
            console.log('entroo mobile')
  
            this.$eventBus.emit('inactiveShowMenu')
          }


        
        
    },
    methods: {
     

    },
    mounted() {

      console.log('Se ejecuta terminos y condiciones');
}
}    
</script>


<style scoped>


</style>