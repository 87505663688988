<template>
  <div v-if="existePromotor" class="row" style="    width: 100%;margin: 0px;">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center"
      style="margin-bottom: 20px;margin-top:15px">

      <router-link :to="{ name: 'Dashboard' }" target="_blank"><img src="" style="width: 200px; " /></router-link>



    </div>



    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

    </div>

    <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" align="center">

      <div>
        <Skeletor :shimmer="true" height="10" width="100%" pill style="margin-bottom:10px" />
        <Skeletor :shimmer="true" height="10" width="50%" pill style="margin-bottom:50px" />

        <img class="d-none d-sm-none d-md-none d-lg-inline d-xl-inline" src="" style="width: 300px;margin-top:15px" />
      </div>




    </div>

    <div v-else class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" align="center" style="    display: flex; ">


      <div style="margin:auto">

        <h1 class="invitacion_txt" style="margin-bottom:50px">¡Hola! soy <span
            style="color:#6602cc; font-weight: bold;text-transform: capitalize">{{ displayName }} </span> y te invito a que
          formes parte de mi equipo de JubonesNet</h1>

        <img class="d-none d-sm-none d-md-none d-lg-inline d-xl-inline" src="" style="width: 300px;margin-top:15px " />

      </div>



    </div>


    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">

      <div class="card">


        <div class="card-body">

          <div class="row">

            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" align="center">

              <Skeletor v-if="loading" circle size="80" style="margin-bottom:10px;margin-top: -50px;" />

              <v-lazy-image v-else :src="photo" class="rounded-circle"
                style="background: white;width: 80px; margin-bottom:10px;margin-top: -50px;" />

            </div>


            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

            </div>


          </div>


          <div v-if="loading">
            <Skeletor :shimmer="true" height="10" width="100%" pill style="margin-bottom:10px" />
            <Skeletor :shimmer="true" height="10" width="80%" pill style="margin-bottom:10px" />
            <Skeletor :shimmer="true" height="10" width="60%" pill style="margin-bottom:10px" />
            <Skeletor :shimmer="true" height="10" width="30%" pill style="margin-bottom:10px" />

          </div>



          <div v-else class="biografiatext">



            <p style="font-size: 14px;text-align: justify;margin-bottom: 0px;line-height: 20px;">{{ description }}</p>
          </div>

        </div>



      </div>

      <div class="card" style="margin-top:10px">


        <div class="card-body">

          <div v-if="loading" align="center">
            <Skeletor :shimmer="true" height="20" width="50%" pill style="margin-bottom:15px" />
            <Skeletor :shimmer="true" height="40" width="100%" style="margin-bottom:30px" />

            <Skeletor :shimmer="true" height="40" width="100%" style="margin-bottom:10px" />

            <div class="text-center">
              <span>~ o ~</span>
            </div>

            <Skeletor :shimmer="true" height="40" width="100%" style="margin-bottom:10px" />
          </div>


          <formulario v-else :is-login="isLogin" :promo-code="promoCode" :texto-button="textoButton" />



        </div>

      </div>

    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-center align-items-center justify-content-xl-between"
      style="color:black !important;margin-top: 15px; ">
      <hr class="horizontal dark" />


      <a target="_blank" title="Facebook de JubonesNet" href="#"> <i
          class="fab fa-facebook-f redes" aria-hidden="true"></i> </a>
      <a target="_blank" title="Instagram de JubonesNet" href="#"> <i
          class="fab fa-instagram redes" aria-hidden="true"></i> </a>
      <a target="_blank" title="Email de JubonesNet" href="#"> <i
          class="fas fa-envelope redes" aria-hidden="true"></i> </a>
      <a target="_blank" title="Tiktok de JubonesNet" href="#"> <svg
          style="margin-bottom: 10px;" xmlns="http://www.w3.org/2000/svg" fill="#7d0c7e" widht="10" height="22"
          viewBox="0 0 448 512">
          <path
            d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
        </svg></a>

      <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> <a
          href="#"
          target="_blank">Términos y condiciones</a> - <a
          href="#"
          target="_blank">Política de privacidad</a> - <a
          href="#"
          target="_blank">Aviso legal</a>- <a
          href="#"
          target="_blank">Disclaimer</a></p>

      <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }} <a
          href="#" target="_blank">- JubonesNet</a></p>

    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

    </div>



  </div>

  <div v-else class="row" style="    width: 100%;margin: 0px;">

    <not-promotor />

  </div>
</template>
  
  
<script>
 /*eslint-disable */

import NotPromotor from './NotPromotor'
import Formulario from "../Formulario.vue";


export default {
  name: "Invitacion",
  components: {
    NotPromotor,
    Formulario
  },
  data() {

    let promoCode = this.$route.params.promoCode;


    return {

      loading: true,
      isLogin: true,
      existePromotor: true,
      isMobile: this.$isMobile,
      displayName: "",
      photo: "",
      description: "",
      promoCode: promoCode,
      year: new Date().getFullYear(),
      textoButton: "Registrarte ",
      link: "",
      linkCopy: "",
    }
  },
  methods: {
    
    getInfo(){
          this.loading=true;
         
          
  
          this.$https.post('api/v1/consulUserName', { promoCode: this.promoCode }).then(response => {

            //console.log(response);

            if(response.data.code === 200){
              this.loading=false;
              this.displayName = response.data.displayName
              this.photo = response.data.photo

            }else if(response.data.code === 204){
              this.$router.push({ name: 'login' })
            }




          }).catch(error => {
            this.$router.push({ name: 'login' })
        })

        
        }


  },

  mounted() {
    this.getInfo()

    document.title = 'JubonesNet - Link de referido ';

  }
}

</script>