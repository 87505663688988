<template>
    <div class="card" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTADO DE LAS CAJAS REGISTRADAS</p>

                </div>


                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                    <div v-else @click="refresh">
                        <i style="cursor:pointer; margin:auto" class="fas fa-sync text-sm opacity-10"></i>
                    </div>
                </div>

            </div>

            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                <div class="row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="40" width="50%" />

                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                        <Skeletor :shimmer="true" height="40" width="100%" />

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="table tablesorter">
                            <thead class="thead-light">
                                <tr>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                    </div>

                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                <Skeletor :shimmer="true" circle size="30" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>


                        </div>


                    </div>

                </div>

            </div>

            <div v-else-if="notAllow" class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

                    <img style="width:100px;" src="/img/caution.svg" />

                    <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.
                    </p>


                </div>

            </div>

            <DataTable v-else :columns="columns" :options="options" :data="transactions" class="table tablesorter"
                width="100%">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Fecha/Hora</th>
                        <th data-priority="1">Ip</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th data-priority="2">Acción</th>
                    </tr>
                </thead>





            </DataTable>


        </div>

    </div>
</template>


<script>
/* eslint-disable */
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
    name: "ListadoCobertura",
    components: {
        DataTable
    },

    data() {
        return {
            isMobile: this.$isMobile,
            loading: true,
            transactions: [],
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            columns: [
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        const index = meta.row + 1;
                        return index;
                    }
                },
                {
                    data: 'fech_registro',
                    render: function (data, type, row, meta) {
                        if (type === 'display' || type === 'filter') {
                            const fechaHora = new Date(data);
                            const fechaFormateada = fechaHora.toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            });
                            const horaFormateada = fechaHora.toLocaleTimeString('es-ES', {
                                hour12: false,
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            });
                            return `${fechaFormateada} ${horaFormateada}`;
                        }
                        return data;
                    }
                },
                { data: 'ip' },
                {
                    data: 'position.lat',
                    render: function (data, type, row, meta) {
                        return data;
                    }
                },
                {
                    data: 'position.lng',
                    render: function (data, type, row, meta) {
                        return data;
                    }
                },
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        //console.log('Ingresa al render');
                        //console.log(row);
                        const id = row.id; // Obtén el ID de la fila                        
                        const deleteIcon = `<i data-id="${id}" data-action="delete" class="dataAction redes fas fa-trash-alt" style="cursor:pointer; color: red;"></i>`;
                        return deleteIcon;
                    },
                    className: 'column-centered'
                }
            ],
            options: {
                responsive: true,
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [5, 15, 25, 35, 50, -1],
                    [5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 5,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            },
        };
    },
    computed: {},
    watch: {},

    activated() {

        this.$eventBus.on('showListadoIP', (parametro) => {
            this.loading = false
            console.log('showListadoIP');
            console.log(parametro);
            
                this.transactions = parametro
            

        });

    },
    mounted() {

        if (this.isMobile) {
            this.$eventBus.emit("activeShowMenu");
        }


        $(document).off('click', '.dataAction');
        $(document).on('click', '.dataAction', (evt) => {
            const id = $(evt.target).data('id');
            const action = $(evt.target).data('action');

            if (action === 'delete') {
                //console.log('delete: ' + id);
                this.getElimina(id)

            }
        });


    },

    methods: {

        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "top-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "top-right",
                max: 10
            });
        },

        getElimina(id) {

            this.$swal({
                title: "¿Seguro que deseas eliminar la cobertura?",
                icon: 'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(async (result) => {
                if (result.isConfirmed) {



                    console.log('Emininar id: ' + id);

                    try {
                        const response = await this.$https.post("api/v1/getDeleteCobertura", { id: id }, {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": this.tokenAuth,
                            }
                        });

                        //console.log(response);

                        if (response.data.code === 200) {

                            this.showSuccessToast('Registro Eliminado')
                            this.$eventBus.emit('showRegistroCobertura');

                        }

                    } catch (error) {
                        console.log(error);
                        this.showErrorToast(error.response.data.message2)
                    }


                }
            });

        }

    }

}



</script>


<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';
</style>
