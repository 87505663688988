<template>
  <div>

    <div class="row" v-if="loading">

      <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center"
        style="min-height: calc(var(--vh, 1vh) * 80);display: flex;">
        <div style="margin: auto;text-align: center;">
          <i style="font-size: 50px" class="fas fa-spinner fa-pulse"></i>


          <p style="font-size: 16px; font-weight: bold;">
            Comprobando usuario
          </p>

        </div>

      </div>


    </div>

    <div class="row" v-else>

      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">

        <div class="card" style="border-radius: 0px;">

          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button @click="GestionesLoad" class="nav-link" :class="activoPanel === 1 ? 'active' : ''"
                id="pills-gestiones-tab" data-bs-toggle="pill" data-bs-target="#pills-gestiones" type="button" role="tab"
                aria-controls="pills-gestiones" aria-selected="true"><i style="font-size:14px; margin-right: 5px;"
                  class="fas fa-phone-alt text-success2"></i> Gestiones</button>
            </li>
            <li class="nav-item" role="presentation">
              <button @click="VentasLoad" class="nav-link" :class="activoPanel === 0 ? 'active' : ''"
                id="pills-ventas-tab" data-bs-toggle="pill" data-bs-target="#pills-ventas" type="button" role="tab"
                aria-controls="pills-ventas" aria-selected="true"><i style="font-size:14px; margin-right: 5px;"
                  class="fa fa-shopping-cart text-success2"></i> Ventas</button>
            </li>
            <li class="nav-item" role="presentation">
              <button @click="RetirosLoad" class="nav-link" :class="activoPanel === 0 ? 'active' : ''"
                id="pills-retiros-tab" data-bs-toggle="pill" data-bs-target="#pills-retiros" type="button" role="tab"
                aria-controls="pills-retiros" aria-selected="false"><i style="font-size:14px; margin-right: 5px;"
                  class="fa fa-money text-success2"></i> Retiros</button>
            </li>
            <li class="nav-item" role="presentation">
              <button @click="UsuariosLoad" class="nav-link" :class="activoPanel === 0 ? 'active' : ''"
                id="pills-usuarios-tab" data-bs-toggle="pill" data-bs-target="#pills-usuarios" type="button" role="tab"
                aria-controls="pills-usuarios" aria-selected="false"><i style="font-size:14px; margin-right: 5px;"
                  class="fas fa-users text-success2"></i> Usuarios</button>
            </li>
          </ul>

        </div>



      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="margin-top:15px;">
        <div class="card">
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" :class="{ 'show active': activoPanel === 1 }" id="pills-gestiones" role="tabpanel"
              aria-labelledby="pills-gestiones-tab">
              <listado-gestiones v-if="ifGestiones" />
            </div>
            <div class="tab-pane fade" :class="{ 'show active': activoPanel === 2 }" id="pills-ventas" role="tabpanel"
              aria-labelledby="pills-ventas-tab">
              <listado-ventas v-if="ifVentas" />
            </div>
            <div class="tab-pane fade" :class="{ 'show active': activoPanel === 3 }" id="pills-retiros" role="tabpanel"
              aria-labelledby="pills-retiros-tab" tabindex="0">
              <listado-retiros v-if="ifRetiros" />
            </div>
            <div class="tab-pane fade" :class="{ 'show active': activoPanel === 4 }" id="pills-usuarios" role="tabpanel"
              aria-labelledby="pills-usuarios-tab" tabindex="0">
              <listado-usuarios v-if="ifUsuarios" />
            </div>
          </div>
        </div>
      </div>



    </div>


  </div>
</template>

<script>
/*eslint-disable */
import "bootstrap" //no eliminar, este le da el evento al nav pills
import ListadoGestiones from './ListadoGestiones.vue'
import ListadoVentas from './ListadoVentas.vue'
import ListadoRetiros from './ListadoRetiros.vue'
import ListadoUsuarios from './ListadoUsuarios.vue'


export default {
  components: {
    ListadoVentas,
    ListadoRetiros,
    ListadoGestiones,
    ListadoUsuarios
  },
  data() {

    return {
      isMobile: this.$isMobile,
      idUser: JSON.parse(localStorage.getItem('userData')).code, //datos.userId,
      tokenAuth: '', //datos.tokenAuth,
      idAdmin: "",
      loading: true,
      activoPanel: 0,
      ifVentas: false,
      ifRetiros: false,
      ifGestiones: false,
      ifUsuarios: false
    }
  },
  watch: {
    $route(to, from) {





      var hash = to.hash.substring(1); //Puts hash in variable, and removes the # character



      switch (hash) {
        case "gestiones":
          this.activoPanel = 1;
          break;
        case "ventas":
          this.activoPanel = 0;
          break;
        case "retiros":
          this.activoPanel = 0;
          break;
        case "usuarios":
          this.activoPanel = 0;
          break;
        default:
          this.activoPanel = 0;
      }



    }
  },
  created() {


  },
  activated() {

    if (this.isMobile) {

      this.$eventBus.emit('inactiveShowMenu')
    }

    this.activoPanel = 1;


  },
  mounted() {

    this.activoPanel = 1;

    setInterval(() => {
      this.loading = false
    }, 2000);


    document.title = 'JUBONESNET - Administración';





  },
  methods: {



    init() {


    },

    UsuariosLoad() {
      this.ifUsuarios = true
      this.ifVentas= false,
      this.ifRetiros= false,
      this.ifGestiones= false
    },

    RetirosLoad() {
      this.ifRetiros = true
      this.ifUsuarios = false
      this.ifVentas= false,
      this.ifGestiones= false

    },

    VentasLoad() {
      this.ifVentas = true
      this.ifRetiros = false
      this.ifUsuarios = false
      this.ifGestiones= false

    },

    GestionesLoad() {
      this.ifGestiones = true
      this.ifVentas = false
      this.ifRetiros = false
      this.ifUsuarios = false    
    }
  },
}
</script>

<style lang="scss" ></style>
