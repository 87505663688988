
<template>
  <div>


    <div class="row">


      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div class="card">


          <div class="card-body">

            <p style="    font-size: 16px;font-weight: bold;text-align: center;">Descripción general</p>

            <p style="font-size:14px;text-align: justify;">


              JUBONES NET les ofrece a sus clientes una plataforma digital de emprendimiento que les permite ganar dinero recomendando el servicio a otros.<br>

              Registrense gratuitamente y empiecen a ganar con Jubones Net <b>(REGISTRO COMPLETAMENTE GRATUITO)</b> </p>
            
            <p style="font-size:14px;text-align: justify;">

              Los pagos de comisiones se efectuarán exclusivamente mediante transferencia bancaria
              a la cuenta que hayan registrado en la aplicación. Además, es indispensable que dicha
              cuenta esté a nombre de la persona registrada en la plataforma.</p>
            <p style="font-size:14px;text-align: justify;">

              Si desean pueden registrar en la plataforma a su familia, invitándolos con el link de registro para que
              todos puedan gozar de este beneficio.</p>
            <p style="font-size:14px;text-align: justify;">

              <b>Comisiones en 3 Niveles: </b><br>
            <ul>
              <li style="font-size:14px;"><b>Se detalla las ganancias:</b>
                <ul>
                  <li> Referidor recibe $10.00 USD</li>
                  <li> Nivel 1 recibe $3.00 USD</li>
                  <li> Nivel 2 recibe $2.00 USD</li>
                </ul>
              </li>


            </ul>




            </p>
            <p style="font-size:14px;text-align: justify;">
  Una vez ingreses a tu referido, el Call Center se comunicará con ellos dentro de las
  siguientes 24 horas. <br> Las comisiones se pagarán una vez que el servicio de Jubones Net
  esté instalado en el domicilio de tu referido. <br> Esta plataforma cumple con los estándares
  de seguridad más rigurosos del mercado, por lo que puedes estar seguro de que tus datos
  están protegidos.
</p>



            <p style="    font-size: 16px;font-weight: bold;text-align: center;">Términos y condiciones</p>

            <p style="font-size:14px;text-align: justify;">
              Reconozco y acepto que la plataforma JUBONES NET en la persona de su administrador o titular, se reserva el
              derecho de negarme el acceso a la misma en cualquier caso que considere apropiado, en particular si yo como
              usuario:
            </p>

            <p style="font-size:14px;text-align: justify;">
            <ol>
              <li style="font-size:14px;">Proporciono <b>datos falsos.</b></li>
              <li style="font-size:14px;"><b>Incumplo</b> estos Términos y Condiciones de Uso de cualquier forma.</li>
              <li style="font-size:14px;">Incumplo <b>cualquier normativa legal</b> aplicable respecto del acceso o el uso
                de la presente plataforma. </li>

            </ol>
            </p>

            <p style="font-size:14px;text-align: justify;">
              Acepta libre, voluntaria y expresamente que soy el único responsable del contenido que cargue, publique,
              envíe por correo electrónico, transmita o de cualquier forma ponga a disposición a través de esta
              plataforma.
            </p>

            <p style="font-size:14px;text-align: justify;">

              Adicionalmente me obligo expresa, libre y voluntariamente a <b>no utilizar la presente plataforma en forma
                alguna que sirva directa o indirectamente para:</b>
            </p>

            <p style="font-size:14px;text-align: justify;">
            <ul>
              <li style="font-size:14px;">Dañar a otras personas de cualquier forma; </li>
              <li style="font-size:14px;">Realizar declaraciones falsas; </li>
              <li style="font-size:14px;">Difundir de cualquier forma contenido que viole un derecho de propiedad
                intelectual de terceros, incluyendo pero no limitado a marcas, derechos de autor, secretos empresariales,
                patentes y diseños industriales; y, </li>
              <li style="font-size:14px;">Violar cualquier ley o norma jurídica nacional o internacional. </li>
            </ul>
            </p>

            <p style="font-size:14px;text-align: justify;">

              Reconozco y acepto que la plataforma JUBONES NET en la persona de su administrador o titular se reserva el
              derecho de modificar a su discreción los presentes términos y condiciones, comunicándome el particular,
              siempre de acuerdo con la ley ecuatoriana.

            </p>

            <p style="font-size:14px;text-align: justify;">

              <b>Datos Personales:</b> Autorizo expresamente a JUBONES NET a utilizar mis datos personales entregados o
              generados por medio de mi utilización de la plataforma. Esta autorización incluye los siguientes usos:
            </p>

            <p style="font-size:14px;text-align: justify;">
            <ul>
              <li style="font-size:14px;">Acceso a datos. </li>
              <li style="font-size:14px;">Consulta de buró crediticio. </li>
            </ul>
            </p>



          </div>
        </div>
      </div>



    </div>


  </div>
</template>

<script>
/*eslint-disable */


export default {
  name: "Descripcion",
  data() {



    return {
      isMobile: this.$isMobile,
    }
  },
  methods: {


  },
  activated() {

    if (this.isMobile) {

      this.$eventBus.emit('inactiveShowMenu')
    }




  },
  mounted() {


  }
}    
</script>


<style scoped></style>