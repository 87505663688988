<template>
    <div class="card" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTA DE VENTAS REALIZADAS</p>

                </div>


                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                    <div v-else @click="refresh">
                        <i style="cursor:pointer; margin:auto" class="fas fa-sync text-sm opacity-10"></i>
                    </div>
                </div>

            </div>


            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                <div class="row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="40" width="50%" />

                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                        <Skeletor :shimmer="true" height="40" width="100%" />

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="table tablesorter">
                            <thead class="thead-light">
                                <tr>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                    </div>

                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                <Skeletor :shimmer="true" circle size="30" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>


                        </div>


                    </div>

                </div>

            </div>

            <!-- <div v-else-if="notAllow" class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

                    <img style="width:100px;" src="/img/caution.svg" />

                    <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.
                    </p>


                </div>

            </div> -->

            <DataTable v-else :columns="columns" :options="options" :data="transactions" class="table tablesorter"
                width="100%">
                <thead>
                    <tr>
                        <th>#</th>
                        <th data-priority="1">Fecha/Venta</th>
                        <th data-priority="2">Transacción</th>
                        <th data-priority="3">Vendedor</th>
                        <th>P. Nivel 1</th>
                        <th>P. Nivel 2</th>
                        <th data-priority="4">Cliente</th>
                        <th data-priority="5">Comisión</th>
                        <th>Pendiente/Cobro</th>
                    </tr>
                </thead>





            </DataTable>




        </div>

    </div>
</template>

<script>

/* eslint-disable */
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {

    components: {
        DataTable
    },

    data() {

        return {
            transactions: [],
            loading: true,
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            columns: [
            {
                    data: null,
                    render: function (data, type, row, meta) {
                        const index = meta.row + 1;
                        return index;
                    }
                },
                {
                    data: 'fech_venta',
                    render: function (data, type, row, meta) {
                        if (type === 'display' || type === 'filter') {
                            const fechaHora = new Date(data);
                            const fechaFormateada = fechaHora.toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            });
                            const horaFormateada = fechaHora.toLocaleTimeString('es-ES', {
                                hour12: false,
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            });
                            return `${fechaFormateada} ${horaFormateada}`;
                        }
                        return data;
                    }
                },                
                { data: 'codeVenta' },
                { data: 'Vendedor' },
                { data: 'userPromoterPrimerNivel' },
                { data: 'userPromoterSegundoNivel' },
                { data: 'clienteFinal' },
                { data: 'comision' },
                { data: 'pendiente_cobro' }
            ],
            options: {
                responsive: true,
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [5, 15, 25, 35, 50, -1],
                    [5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 15,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            }

        }
    },
    computed: {

    },
    watch: {

    },
    activated() {

    },

    methods: {

        refresh(){

            this.getListadoVentas()
        },

        async getListadoVentas() {

            try {
                this.loading = true;
                const response = await this.$https.post('/api/v1/getConsulVentasAdmin', {
                    code: '',
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.tokenAuth,
                        // Otros encabezados si es necesario
                    },
                });

                //console.log(response);

                this.loading = false;
                this.transactions = response.data.datos

            } catch (error) {
                console.error(error);
                // Tu código para manejar el error
                console.log("Error al cargar la lista");
                console.log(error.response.status);

                if (error.response.status === 401) {
                    this.$swal
                        .fire({
                            html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                            position: "center",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false, // Oculta el botón de confirmación
                            timer: 4000, // Duración en milisegundos (4 segundos)
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        })
                        .then(
                            () => {
                                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                console.log("closed by timer!!!!");

                                localStorage.clear();
                                location.reload(true);
                            },
                            (dismiss) => {
                                if (dismiss === "timer") {
                                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                    console.log("closed by timer!!!!");
                                    this.$swal.fire({
                                        title: "Finished!",
                                        icon: "success",
                                        showConfirmButton: false,
                                    });
                                }
                            }
                        );
                }


            } finally {
                this.loading = false;
            }
        }

    },
    mounted() {
        this.getListadoVentas()
    }


}




</script>
<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';
</style>
  