export default function useComp() {

  async function logout() {

    await this.$swal({
      title: "¿Seguro que deseas cerrar sesión?",
      icon: 'question',
      width: '400px',
      showCancelButton: true,
      confirmButtonText: 'Cerrar sesión',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-secondary ml-1',
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: async () => {

      },
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        // El usuario hizo clic en el botón "Cerrar sesión"





        this.$swal
        .fire({
            html: "<span style='font-size:14px'>Cerrando sesión. Espere... </span>",
            position: "center",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false, // Oculta el botón de confirmación
            timer: 4000, // Duración en milisegundos (4 segundos)
            didOpen: () => {
                this.$swal.showLoading();
            },
        })
        .then(
            () => {
                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                console.log("closed by timer!!!!");

                localStorage.clear();
                location.reload(true);
            },
            (dismiss) => {
                if (dismiss === "timer") {
                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                    console.log("closed by timer!!!!");
                    this.$swal.fire({
                        title: "Finished!",
                        icon: "success",
                        showConfirmButton: false,
                    });
                }
            }
        );



      }
    });
  }

  return {
    logout
  }
}