<template>
    <div>
        <h5 style="text-align: center;margin-bottom: 15px;">GANA DINERO POR REFERIR</h5>
        <form role="form" autocomplete="off" @submit.prevent="registerReferido">
            <div class="row">
                <div class="col-12">
                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; text-align: left;">Ingrese el nombre
                        (*):</label>
                    <input placeholder="Nombres y Apellidos" class="form-control col-lg-12" required type="text"
                        autocomplete="off" v-model="displayName" />
                </div>

                <div class="col-12">


                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Ingrese su número celular (*):</label>

                    <MazPhoneNumberInput type="tel" default-country-code="EC" style="width: 100%;" no-flags no-example
                        required show-code-on-list size="md" v-model="phoneNumber" @update="results = $event" :translations="{
                            countrySelector: {
                                placeholder: 'Código',
                                error: 'Elija un pais',
                            },
                            phoneInput: {
                                placeholder: 'Nro. celular',
                                example: 'Ejemplo:',
                            },
                        }" />


                </div>


                <div class="col-12 mt-1">

                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Ingrese la provincia (*):</label>

                    <select v-model="selectedProvincia" @change="cargarCantones" class="form-select"
                        placeholder="Seleccione una opción" required>
                        <option v-for="provincia in provincias" :key="provincia.id" :value="provincia.id">
                            {{ provincia.provincia }}
                        </option>
                    </select>


                </div>


                <div class="col-12 mt-1">

                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Ingrese el cantón (*):</label>

                    <select v-model="selectedCanton" @change="cargarParroquias" class="form-select" required>
                        <option v-for="canton in cantones" :key="canton.id" :value="canton.id">
                            {{ canton.canton }}
                        </option>
                    </select>


                </div>


                <div class="col-12 mt-1">

                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Escoja la Parroquia (*):</label>

                    <select v-model="selectedParroquia" class="form-select" @change="handleSelectParroquia" required>
                        <option v-for="parroquia in parroquias" :key="parroquia.id" :value="parroquia.id">
                            {{ parroquia.parroquia }}
                        </option>
                    </select>


                </div>



            </div>


            <div class="text-center mt-3">
                <button type="submit" id="btn_submit" class="btn btn-primary" :disabled="isDisabled"> Solicitar servicio
                </button>

            </div>


        </form>
    </div>
</template>
  
  
<script>
/*eslint-disable */
import { VueFinalModal } from 'vue-final-modal'
import 'vue-final-modal/style.css'

export default {
    name: "Venta",
    components: {

    },
    props: {
        prueba: { type: Array, required: true },
        bandera: { type: Boolean, required: true },
    },
    components: {
        VueFinalModal
    },
    data() {

        let datos = JSON.parse(this.$store.state.user);

        return {
            showModal: false,
            isDisabled: true,
            displayName: "",
            name: "",
            phoneNumber: null,
            results: null,
            hasPhone: false,
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            idUser: datos.code,//JSON.parse(localStorage.getItem('userData')).code,
            provincias: [], // Array de provincias
            cantones: [], // Array de cantones
            parroquias: [], // Array de parroquias

            selectedParroquiaInfo: {},
            selectedCantonInfo: {},

            selectedProvincia: null,
            selectedCanton: null,
            selectedParroquia: null,
            provinciaS: '',
            cantonS: '',
            parroquiaS: '',
            datos: {}

        }
    },

    watch: {

        results(newValue) {
            if (newValue.isValid) {

                this.phoneNumber = newValue.e164
                this.hasPhone = true;
                this.isDisabled = false;

                //this.checkPhone();

            } else {
                this.hasPhone = false;

            }
        },
        displayName(newDisplayName) {
            // Verifica que newDisplayName no sea undefined ni null
            if (newDisplayName && newDisplayName.trim && typeof newDisplayName.trim === 'function') {
                // Realiza las operaciones de formato solo si el campo no está en blanco
                this.displayName = newDisplayName.replace(/[^A-Za-z\sñÑáéíóúÁÉÍÓÚ]/g, '');
                this.displayName = this.displayName.toUpperCase();
            }
        },


    },
    methods: {

        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                max: 10
            });
        },

        handleSelectParroquia() {
            // Encuentra la parroquia seleccionada en el arreglo de parroquias
            const parroquiaSeleccionada = this.parroquias.find(parroquia => parroquia.id === this.selectedParroquia);

            if (parroquiaSeleccionada) {
                // Almacena la información de la parroquia seleccionada
                this.selectedParroquiaInfo = parroquiaSeleccionada;
            } else {
                // Si no se encuentra la parroquia, restablece la información
                this.selectedParroquiaInfo = {};
            }

            this.parroquiaS = this.selectedParroquiaInfo.parroquia
        },




        async cargarProvincias() {
            const response = await this.$https.get("api/v1/getSitiosPais");
            this.datos = response.data.datos;

            this.provincias = Object.keys(this.datos).map(key => ({
                id: key,
                provincia: this.datos[key]?.provincia?.trim() || ''
            })).filter(provincia => provincia.provincia !== '');

        },

        async cargarCantones() {

            const provinciaSeleccionada = this.datos[this.selectedProvincia];
            this.provinciaS = (provinciaSeleccionada.provincia);

            if (this.selectedProvincia) {
                const provinciaData = this.datos[this.selectedProvincia];
                this.cantones = Object.keys(provinciaData.cantones).map(key => ({
                    id: key,
                    canton: provinciaData.cantones[key].canton.trim() || ''
                })).filter(canton => canton.canton !== '');
            }
        },

        async cargarParroquias() {

            const provinciaData = this.datos[this.selectedProvincia];
            const cantonData = provinciaData.cantones[this.selectedCanton];
            this.cantonS = cantonData.canton;

            this.parroquias = Object.keys(cantonData.parroquias).map(key => ({
                id: key,
                parroquia: cantonData.parroquias[key].trim() || ''
            })).filter(parroquia => parroquia.parroquia !== '');

        },



        async registerReferido(event) {
            event.preventDefault();

            console.log('Ingresa a guardar');
            console.log(this.hasPhone);

            if (this.hasPhone) {
                document.getElementById('btn_submit').innerHTML = '<i class="fas fa-spinner fa-pulse"></i>  Registrando'


                let datos = {
                    code: this.idUser,
                    name: this.displayName.toUpperCase(),
                    movil: this.phoneNumber,
                    provincia: this.provinciaS.toUpperCase(),
                    canton: this.cantonS.toUpperCase(),
                    parroquia: this.parroquiaS.toUpperCase()
                }

                try {

                    const response = await this.$https.post("api/v1/getAddReferidos", datos, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": this.tokenAuth,
                        }
                    });

                    console.log(response);
                    if (response.data.code === 200) {
                        //this.showSuccessToast('Grabación Éxitosa')

                        this.$eventBus.emit('showPedro')

                        this.$swal({
                            title: 'Grabación Éxitosa',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Entendido',
                            focusConfirm: true,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });

                    } else if (response.data.code === 204) {

                        this.$swal({
                            title: response.data.message,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Entendido',
                            focusConfirm: true,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });



                    } else {
                        this.showErrorToast('Ha ocurrido un error')
                    }

                    document.getElementById('btn_submit').innerHTML = 'Solicitar servicio'
                    this.isDisabled = false;

                } catch (error) {

                    //console.log(error.response.data);

                    if (error.response.data.code === 300) {




                        this.$swal
                            .fire({
                                html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                                position: "center",
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showConfirmButton: false, // Oculta el botón de confirmación
                                timer: 4000, // Duración en milisegundos (4 segundos)
                                didOpen: () => {
                                    this.$swal.showLoading();
                                },
                            })
                            .then(
                                () => {
                                    // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                    console.log("closed by timer!!!!");

                                    localStorage.clear();
                                    location.reload(true);
                                },
                                (dismiss) => {
                                    if (dismiss === "timer") {
                                        // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                        console.log("closed by timer!!!!");
                                        this.$swal.fire({
                                            title: "Finished!",
                                            icon: "success",
                                            showConfirmButton: false,
                                        });
                                    }
                                }
                            );



                    }

                }



            } else {

                this.$toast.error("El número celular es un campo obligatorio", {
                    position: "top-right",
                    max: 10
                })

            }

        }

    },

    activated() {



    },
    mounted() {


        this.phoneNumber = this.prueba[0];
        this.displayName = this.prueba[1];


    },
    created() {
        this.cargarProvincias();
    },
    beforeUnmount() {

    },
};
</script>
  
<style scoped></style>
  