<template>
    <div class="card" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTADO DE INTERESADOS PENDIENTES EN CONTRATAR</p>

                </div>


                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                    <div v-else @click="refresh">
                        <i style="cursor:pointer; margin:auto" class="fas fa-sync text-sm opacity-10"></i>
                    </div>
                </div>

            </div>

            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                <div class="row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="40" width="50%" />

                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                        <Skeletor :shimmer="true" height="40" width="100%" />

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="table tablesorter">
                            <thead class="thead-light">
                                <tr>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                    </div>

                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                <Skeletor :shimmer="true" circle size="30" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>


                        </div>


                    </div>

                </div>

            </div>

            <div v-else-if="notAllow" class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

                    <img style="width:100px;" src="/img/caution.svg" />

                    <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.
                    </p>


                </div>

            </div>

            <DataTable v-else :columns="columns" :options="options" :data="transactions" class="table tablesorter"
                width="100%">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Fecha/Hora</th>
                        <th>Vendedor</th>
                        <th data-priority="1">Cliente</th>
                        <th>DNI</th>
                        <th data-priority="2">Celular</th>
                        <th>Provincia</th>
                        <th>Cantón</th>
                        <th>Parroquia</th>
                        <th>Observación</th>
                        <th>Estado</th>
                        <th data-priority="3">Editar</th>
                        <th data-priority="4">Acción</th>
                    </tr>
                </thead>





            </DataTable>


        </div>

    </div>



    <VueFinalModal v-model="showModal" :esc-to-close="true" @closed="closed" content-class="modal-content"
        class="modal-container">

        <div class="modal__content">

            <div class="modal-header d-flex justify-content-between align-items-center">
                <!-- Título centrado -->
                <h3 style="margin: 0 auto; color: #001fd0;">Actualizar Información del Cliente</h3>

                <!-- Icono de cierre a la derecha -->
                <i class="fa fa-times-circle" style="cursor: pointer;" @click="closed"></i>
            </div>




            <div class="row">



                <div class="offset-md-3 col-md-6 col-lg-6 col-xl-6">
                    <label for="example-text-input" class="form-control-label" style="font-weight: bold; font-size: 12px;">
                        Ingrese su número de cédula (*):
                    </label>
                    <div class="input-group mb-3">
                        <input type="tel" required class="form-control" ref="myDni" placeholder="Número de cédula"
                            v-model="user.dni" @input="onInput" aria-label="Número de cédula"
                            aria-describedby="button-addon2">
                        <button @click="getCedula" class="btn-outline-secondary btn2" type="submit" :disabled="isDisabled">
                            <i class="fa fa-search" aria-hidden="true" style="margin-right: 5px;"></i>
                            <b>Buscar</b>
                        </button>
                    </div>
                </div>

            </div>




            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; text-align: left;">Ingrese su nombre
                        (*):</label>
                    <input placeholder="Nombres y Apellidos" class="form-control col-lg-12" required type="tel"
                        autocomplete="off" v-model="user.displayName" />
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Ingrese su número celular (*):</label>

                    <MazPhoneNumberInput type="tel" default-country-code="EC" style="width: 100%;" no-flags no-example
                        required show-code-on-list size="sm" v-model="phoneNumber" @update="results = $event" :translations="{
                            countrySelector: {
                                placeholder: 'Código',
                                error: 'Elija un pais',
                            },
                            phoneInput: {
                                placeholder: 'Nro. celular',
                                example: 'Ejemplo:',
                            },
                        }" />
                </div>
            </div>



            <div class="row">
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">

                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Ingrese la provincia (*):</label>


                    <select v-model="selectedProvincia" class="form-select" placeholder="Seleccione una opción" required>
                        <option v-for="provincia in provincias" :key="provincia.id" :value="provincia.id">
                            {{ provincia.provincia }}
                        </option>
                    </select>


                </div>

                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Ingrese el cantón (*):</label>



                    <select v-model="selectedCanton" @change="cargarParroquias" class="form-select" required>
                        <option v-for="canton in cantones" :key="canton.id" :value="canton.id">
                            {{ canton.canton }}
                        </option>
                    </select>

                </div>


                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">

                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Escoja la Parroquia (*):</label>

                    <select v-model="selectedParroquia" @change="handleSelectParroquia" class="form-select" required>
                        <option v-for="parroquia in parroquias" :key="parroquia.id" :value="parroquia.id">
                            {{ parroquia.parroquia }}
                        </option>
                    </select>

                </div>


            </div>


            <div class="row">
                <div class="col-12">

                    <label for="example-text-input" class="form-control-label"
                        style="font-weight: bold; font-size: 12px; ">Ingrese alguna observación (*):</label>

                    <textarea required class="form-control" v-model="user.observacion"
                        placeholder="Escriba alguna novedad..." rows="3"></textarea>


                </div>

            </div>

            <div class="text-center mt-3">
                <button type="submit" id="btn_submit" @click="modificarAdmin1" class="btn btn-primary"> Guardar Gestión
                </button>

            </div>






        </div>


    </VueFinalModal>
</template>

<script>

/* eslint-disable */

import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';
import $ from "jquery";
import { VueFinalModal } from 'vue-final-modal';
import 'vue-final-modal/style.css'

DataTable.use(DataTablesCore);

export default {

    components: {
        DataTable,
        VueFinalModal
    },

    data() {

        let datos = JSON.parse(this.$store.state.user);

        return {
            isDisabled: false,
            loading: true,
            textAyuda: "https://api.whatsapp.com/send?phone=",
            user: {
                idUser: datos.code,
                displayName: "",
                movil: "",
                dni: "",
                nacimiento: "",
                estadoCivil: "",
                sexo: "",
                observacion: "",
                nombreParroquiaPredeterminada: null,
                nombreCantonPredeterminado: null,
                nombreProvinciaPredeterminada: null,

            },

            provincias: [], // Array de provincias
            cantones: [], // Array de cantones
            parroquias: [], // Array de parroquias

            selectedParroquiaInfo: {},
            selectedCantonInfo: {},
            selectedProvincia: null,
            selectedCanton: null,
            selectedParroquia: null,




            datos: {},

            phoneNumber: null,
            results: null,
            hasPhone: false,
            idUser: JSON.parse(localStorage.getItem('userData')).code,
            showModal: false,
            loading: false,
            notAllow: false,
            transactions: [],
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
            columns: [
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        const index = meta.row + 1;
                        return index;
                    }
                },
                {
                    data: 'fech_registro',
                    render: function (data, type, row, meta) {
                        if (type === 'display' || type === 'filter') {
                            const fechaHora = new Date(data);
                            const fechaFormateada = fechaHora.toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            });
                            const horaFormateada = fechaHora.toLocaleTimeString('es-ES', {
                                hour12: false,
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            });
                            return `${fechaFormateada} ${horaFormateada}`;
                        }
                        return data;
                    }
                },
                { data: 'vendedor' },
                { data: 'displayName' },
                { data: 'dniReferido' },
                { data: 'movil' },
                { data: 'provincia' },
                { data: 'canton' },
                { data: 'parroquia' },
                { data: 'observacion' },
                { data: 'estado' },
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        const datos = String(`valor1: ${data.movil} valor2: ${data.displayName} valor3: ${data.dniReferido} valor4: ${data.provincia} valor5: ${data.canton} valor6: ${data.parroquia} valor7: ${data.observacion} valor8: ${data.estado}`);
                        const id = data.movil //Obtén el ID de la fila
                        const editIcon = `<i data-id="${datos}" data-action="edit" class="dataAction fas redes fa-edit" style="cursor:pointer; margin-right: 10px; color: #344767;"></i>`;
                        const editMovil = `<i data-id="${id}" data-action="whatsapp" class="dataAction fab redes fa-whatsapp" style="cursor:pointer; margin-right: 10px; color: #344767;"></i>`;

                        // Envuelve los dos íconos en un contenedor div
                        const iconsContainer = `<div style="display: flex;">${editIcon}${editMovil}</div>`;

                        return iconsContainer;
                    },

                    className: 'column-centered'
                },

                {
                    data: null, render: function (data, type, row, meta) {
                        let backgroundColor = '';
                        let coloR = '';
                        switch (data.estado) {
                            case 'INSTALADO: Contrato instalado':
                                backgroundColor = 'green!important';  // Color de fondo verde para este estado
                                coloR = 'white'
                                break;
                            case 'NO DESEA':
                                backgroundColor = 'red!important';   // Color de fondo rojo para este estado
                                coloR = 'white'
                                break;
                            case 'SIN COBERTURA / NO APLICA':
                                backgroundColor = 'orange!important';   // Color de fondo rojo para este estado
                                coloR = 'white'
                                break;
                            case 'PEND INSTALACIÓN':
                                backgroundColor = '#139bff!important';   // Color de fondo rojo para este estado
                                coloR = 'white'
                                break;
                            case 'NO CONTESTA':
                                backgroundColor = '#ef3e86!important';   // Color de fondo rojo para este estado
                                coloR = 'white'
                                break;
                            case 'EN NEGOCIACIÓN':
                                backgroundColor = '#a02da6!important';   // Color de fondo rojo para este estado
                                coloR = 'white'
                                break;
                            case 'DESINFORMADO':
                                backgroundColor = '#008b8b!important';   // Color de fondo rojo para este estado
                                coloR = 'white'
                                break;                                

                            default:
                                backgroundColor = 'blue!important';   // Color de fondo rojo para este estado
                                coloR = 'white'
                        }

                        return (`
            <div class="dropdown">
                <button style="background-color: ${backgroundColor}; color: ${coloR}" class="btn btn-sm dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                ${data.estado}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li><button data-id="${data.movil}" data-estado="INSTALADO: Contrato instalado"  class="aprobar dropdown-item" type="button">INSTALADO: Contrato instalado</button></li>
                <li><button data-id="${data.movil}" data-estado="NO DESEA"  class="aprobar dropdown-item" type="button">NO DESEA</button></li>
                <li><button data-id="${data.movil}" data-estado="SIN COBERTURA / NO APLICA"  class="aprobar dropdown-item" type="button">SIN COBERTURA / NO APLICA</button></li>
                <li><button data-id="${data.movil}" data-estado="EN NEGOCIACIÓN"  class="aprobar dropdown-item" type="button">EN NEGOCIACIÓN</button></li>
                <li><button data-id="${data.movil}" data-estado="NO CONTESTA"  class="aprobar dropdown-item" type="button">NO CONTESTA</button></li>
                <li><button data-id="${data.movil}" data-estado="PEND INSTALACIÓN"  class="aprobar dropdown-item" type="button">PEND INSTALACIÓN</button></li>
                <li><button data-id="${data.movil}" data-estado="DESINFORMADO"  class="aprobar dropdown-item" type="button">DESINFORMADO</button></li>
                </ul>
            </div>


                        `)
                    }
                },


            ],
            options: {
                responsive: true,
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [5, 15, 25, 35, 50, -1],
                    [5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 5,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            },

        }
    },
    computed: {

    },
    watch: {
        results(newValue) {
            if (newValue.isValid) {

                this.phoneNumber = newValue.e164
                this.user.movil = this.phoneNumber
                this.hasPhone = true;
                this.isDisabled = false;

                //this.checkPhone();

            } else {
                this.hasPhone = false;

            }
        },

        'user.observacion': function (newObservacion, oldObservacion) {
            this.user.observacion = newObservacion.toUpperCase();
        },

        'user.displayName': function (newDisplayName) {
            // Verifica que newDisplayName no sea undefined ni null
            if (newDisplayName != null && typeof newDisplayName.trim === 'function') {
                // Realiza las operaciones de formato solo si el campo no está en blanco
                const trimmedName = newDisplayName
                if (trimmedName !== '') {
                    this.user.displayName = trimmedName.replace(/[^A-Za-z\sñÑáéíóúÁÉÍÓÚ]/g, '').toUpperCase();
                }
            }
        },


        selectedProvincia: function (newProvincia) {
            // Llama a cargarCantones y cargarParroquias cuando cambia la provincia seleccionada
            this.cargarCantones();
            this.cargarParroquias();
        },


    },

    activated() {



    },


    mounted() {

        console.log('Probando el estado global:' + this.idUser);


        $(document).off('click', '.consultar');
        $(document).off('click', '.aprobar');

        $(document).on("click", ".aprobar", (evt) => {


            const id = $(evt.target).data('id');
            const estado = $(evt.target).data('estado');

            this.getApruebacion(id, estado)



        });


        $(document).on("click", ".consultar", (evt) => {

            const data = $(evt.target).data('id');

        });





        this.getListado()

        $(document).off('click', '.dataAction');
        $(document).on('click', '.dataAction', (evt) => {
            const id = $(evt.target).data('id');
            const action = $(evt.target).data('action');

            if (action === 'edit') {

                this.validarAdmin(id)

            } else if (action === 'whatsapp') {
                
                var linkWhatsApp = document.createElement('a');
                linkWhatsApp.href = this.textAyuda + id.substring(1); //elimino el +
                linkWhatsApp.target = "_blank";
                linkWhatsApp.click();

            }
        });



    },

    created() {
        this.cargarProvincias();
    },

    methods: {

        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "top-right",
                max: 10
            });
        },

        onInput() {
            // Filtrar caracteres no numéricos utilizando una expresión regular
            this.user.dni = this.user.dni.replace(/\D/g, '');
        },

        async getCedula() {

            if (this.user.dni.length != 10) {
                return this.showErrorToast('La cédula debe tener 10 dígitos');
            }


            this.$swal({

                html:
                    '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'top',
            });


            try {



                const data = {
                    dni: this.user.dni,
                };

                const response = await this.$https.post("api/v1/getConsultaCedula", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.tokenAuth,
                    }
                });

                //console.log(response);

                if (response.data.code === 200) {
                    this.$swal.close();

                    //console.log(response)
                    this.user.displayName = response.data.datos.nombres
                    this.user.nacimiento = response.data.datos.fecha_nacimiento;
                    this.user.estadoCivil = response.data.datos.estado_civil;
                    this.user.sexo = response.data.datos.sexo;

                    console.log(this.user);

                }else if(response.data.code === 204){
                    this.$swal.close()
                    //this.showErrorToast(response.data.message)
                    this.$swal.fire({
                                        title: response.data.message,
                                        icon: "warning",
                                        showConfirmButton: false,
                                    });

                }

            } catch (error) {
                this.$swal.close()
                console.log('Viene al error');
            }




        },

        validarAdmin(datos) {

            this.selectedProvincia = ''
            this.selectedCanton = ''
            this.selectedParroquia = ''

            //console.log(datos);

            //return
            // Utilizamos una expresión regular para extraer los valores de cada grupo
            const regex = /valor1: (.+?) valor2: (.+?) valor3: (.+?) valor4: (.+?) valor5: (.+?) valor6: (.+?) valor7: (.+?) valor8: (.+?)$/;
            const matches = datos.match(regex);

            if (matches) {

                const valores = {
                    valor1: matches[1],
                    valor2: matches[2],
                    valor3: matches[3],
                    valor4: matches[4],
                    valor5: matches[5],
                    valor6: matches[6],
                    valor7: matches[7],
                    valor8: matches[8]
                };


                this.phoneNumber = valores['valor1']
                this.user.displayName = valores['valor2']

                this.user.nombreProvinciaPredeterminada = valores['valor4'];
                const nombreProvinciaPredeterminada = this.user.nombreProvinciaPredeterminada

                //return console.log('Provinciaaaaa: ' + nombreProvinciaPredeterminada);

                // Obtén las claves (códigos de provincia) del objeto
                const codigosProvincias = Object.keys(this.datos);

                // Buscar la provincia por nombre
                const provinciaEncontrada = codigosProvincias.find(codigoProvincia => this.datos[codigoProvincia].provincia === nombreProvinciaPredeterminada);

                // Establecer la selección si se encuentra la provincia
                if (provinciaEncontrada) {
                    this.selectedProvincia = provinciaEncontrada;
                }

                if(valores['valor3'] === null || valores['valor3'] === 'null' || valores['valor3'] === undefined){
                    this.user.dni = ""
                }

                if (valores['valor3'] != null && valores['valor3'] !== '' && valores['valor3'].toUpperCase() !== 'NULL') {
                    this.user.dni = valores['valor3'];
                }




                if (
                    valores['valor7'] !== null &&
                    valores['valor7'] !== undefined &&
                    valores['valor7'] !== '' &&
                    valores['valor7'].toUpperCase() !== 'NULL'
                ) {
                    this.user.observacion = valores['valor7'];
                }




                //Encuentra canton
                this.user.nombreCantonPredeterminado = valores['valor5'];
                const nombreCantonPredeterminado = this.user.nombreCantonPredeterminado

                let codigoCantonEncontrado = null;

                for (const codigoProvincia in this.datos) {
                    const provincia = this.datos[codigoProvincia];
                    const cantones = provincia.cantones;

                    for (const codigoCanton in cantones) {
                        const canton = cantones[codigoCanton];

                        if (canton.canton === nombreCantonPredeterminado) {
                            codigoCantonEncontrado = codigoCanton;
                            break;
                        }
                    }

                    if (codigoCantonEncontrado) {
                        break;
                    }
                }

                // Establecer la selección de cantón si se encuentra
                if (codigoCantonEncontrado) {
                    this.selectedCanton = codigoCantonEncontrado;
                    //console.log('Cantón encontrado:', this.selectedCanton);
                } else {
                    //console.log('Cantón no encontrado');
                }




                //Parroquia
                this.user.nombreParroquiaPredeterminada = valores['valor6'];
                const nombreParroquiaPredeterminada = this.user.nombreParroquiaPredeterminada

                let codigoParroquiaEncontrada = null;

                for (const codigoProvincia in this.datos) {
                    const provincia = this.datos[codigoProvincia];
                    const cantones = provincia.cantones;

                    for (const codigoCanton in cantones) {
                        const canton = cantones[codigoCanton];
                        const parroquias = canton.parroquias;

                        for (const codigoParroquia in parroquias) {
                            const parroquia = parroquias[codigoParroquia];

                            if (parroquia === nombreParroquiaPredeterminada) {
                                codigoParroquiaEncontrada = codigoParroquia;
                                break;
                            }
                        }

                        if (codigoParroquiaEncontrada) {
                            break;
                        }
                    }

                    if (codigoParroquiaEncontrada) {
                        break;
                    }
                }

                // Establecer la selección de parroquia si se encuentra
                if (codigoParroquiaEncontrada) {
                    this.selectedParroquia = codigoParroquiaEncontrada;
                    //console.log('Parroquia encontrada:', this.selectedParroquia);
                } else {
                    //console.log('Parroquia no encontrada');
                }






                this.showModal = true

            }





        },

        modificarAdmin1() {


            this.$swal({
                title: "¿Seguro que deseas guardar la información?",
                icon: 'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {

                    this.modificarAdmin()

                }
            });

        },


        async modificarAdmin() {

            try {



                const response = await this.$https.post("api/v1/getUpdateReferidos", this.user, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.tokenAuth,
                    }
                });

                //console.log(response);

                if (response.data.code === 200) {

                    this.getListado()

                    this.$swal({
                        title: 'Grabación Éxitosa',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Entendido',
                        focusConfirm: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });

                } else if (response.data.code === 204) {
                    this.$swal({
                        title: 'Ha ocurrido un error',
                        icon: 'info',
                        text: response.data.message,
                        showCancelButton: false,
                        confirmButtonText: 'Entendido',
                        focusConfirm: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                }

            } catch (error) {

                if (error.response.data.code === 300) {

                    this.$swal
                        .fire({
                            html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                            position: "center",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false, // Oculta el botón de confirmación
                            timer: 4000, // Duración en milisegundos (4 segundos)
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        })
                        .then(
                            () => {
                                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                console.log("closed by timer!!!!");

                                localStorage.clear();
                                location.reload(true);
                            },
                            (dismiss) => {
                                if (dismiss === "timer") {
                                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                    console.log("closed by timer!!!!");
                                    this.$swal.fire({
                                        title: "Finished!",
                                        icon: "success",
                                        showConfirmButton: false,
                                    });
                                }
                            }
                        );


                } else {
                    this.showErrorToast(error.response.data.messaje)
                }

            }





        },


        handleSelectParroquia() {
            // Encuentra la parroquia seleccionada en el arreglo de parroquias
            const parroquiaSeleccionada = this.parroquias.find(parroquia => parroquia.id === this.selectedParroquia);

            if (parroquiaSeleccionada) {
                // Almacena la información de la parroquia seleccionada
                this.selectedParroquiaInfo = parroquiaSeleccionada;
            } else {
                // Si no se encuentra la parroquia, restablece la información
                this.selectedParroquiaInfo = {};
            }

            this.parroquiaS = this.selectedParroquiaInfo.parroquia
        },

        handleSelectParroquia() {
            // Encuentra la parroquia seleccionada en el arreglo de parroquias
            const parroquiaSeleccionada = this.parroquias.find(parroquia => parroquia.id === this.selectedParroquia);

            if (parroquiaSeleccionada) {
                // Almacena la información de la parroquia seleccionada
                this.selectedParroquiaInfo = parroquiaSeleccionada;
            } else {
                // Si no se encuentra la parroquia, restablece la información
                this.selectedParroquiaInfo = {};
            }

            this.user.nombreParroquiaPredeterminada = this.selectedParroquiaInfo.parroquia
        },


        async cargarProvincias() {
            const response = await this.$https.get("api/v1/getSitiosPais");
            this.datos = response.data.datos;

            this.provincias = Object.keys(this.datos).map(key => ({
                id: key,
                provincia: this.datos[key]?.provincia?.trim() || ''
            })).filter(provincia => provincia.provincia !== '');

        },

        async cargarCantones() {

            const provinciaSeleccionada = this.datos[this.selectedProvincia];
            //this.provinciaS = (provinciaSeleccionada.provincia);
            this.user.nombreProvinciaPredeterminada = provinciaSeleccionada.provincia

            if (provinciaSeleccionada) {
                const provinciaData = this.datos[this.selectedProvincia];
                this.cantones = Object.keys(provinciaData.cantones).map(key => ({
                    id: key,
                    canton: provinciaData.cantones[key].canton.trim() || ''
                })).filter(canton => canton.canton !== '');
            }
        },

        async cargarParroquias() {
            const provinciaData = this.datos[this.selectedProvincia];
            const cantonData = provinciaData.cantones[this.selectedCanton];
            this.user.nombreCantonPredeterminado = cantonData.canton

            if (provinciaData && provinciaData.cantones) {
                const cantonData = provinciaData.cantones[this.selectedCanton];

                if (cantonData && cantonData.parroquias) {
                    this.parroquias = Object.keys(cantonData.parroquias).map(key => ({
                        id: key,
                        parroquia: cantonData.parroquias[key].trim() || ''
                    })).filter(parroquia => parroquia.parroquia !== '');
                }
            } else {
                console.error('Error: No se encontraron datos para la provincia seleccionada.');
            }
        },



        closed() {
            this.showModal = false;
        },

        deleteUser(id) {
            console.log('Viene metodo a eliminar: ' + id);
        },

        refresh() {

            this.getListado()

        },

        async getListado() {

            try {
                this.loading = true;
                const response = await this.$https.post('/api/v1/getconsReferidosPromotorAll', {
                    code: this.idUser,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: this.tokenAuth,
                        // Otros encabezados si es necesario
                    },
                });

                //console.log(response);

                this.loading = false;

                if (response.data.code === 200) {
                    this.transactions = response.data.datos;
                } else if (response.data.code === 204) {
                    this.transactions = response.data.datos;
                } else {
                    console.log('error');
                }





            } catch (error) {
                console.error(error);
                // Tu código para manejar el error
                console.log("Error al cargar la lista");
                console.log(error.response.status);

                if (error.response.status === 401) {
                    this.$swal
                        .fire({
                            html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                            position: "center",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false, // Oculta el botón de confirmación
                            timer: 4000, // Duración en milisegundos (4 segundos)
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        })
                        .then(
                            () => {
                                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                console.log("closed by timer!!!!");

                                localStorage.clear();
                                location.reload(true);
                            },
                            (dismiss) => {
                                if (dismiss === "timer") {
                                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                    console.log("closed by timer!!!!");
                                    this.$swal.fire({
                                        title: "Finished!",
                                        icon: "success",
                                        showConfirmButton: false,
                                    });
                                }
                            }
                        );
                }


            } finally {
                this.loading = false;
            }
        },


        getApruebacion(movil, estado) {


            this.$swal({
                title: `¿Seguro que deseas cambiar de estado a ${estado}?`,
                icon: 'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {

                    this.getApruebacion1(movil, estado)

                }
            });

        },

        async getApruebacion1(movil, estado) {

            try {

                const datos = { movil, estado }
                const response = await this.$https.post("api/v1/getUpdateEstadoContratacion", datos, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.tokenAuth,
                    }
                });

                //console.log(response);

                if (response.data.code === 200) {
                    //this.$swal.close();
                    this.getListado()
                    

                } else if (response.data.code === 204) {
                    this.$swal({
                        title: 'Ha ocurrido un error',
                        icon: 'info',
                        text: response.data.message,
                        showCancelButton: false,
                        confirmButtonText: 'Entendido',
                        focusConfirm: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });

                } else if (response.data.code === 208) {

                    this.$swal({
                        title: 'Ha ocurrido un error',
                        icon: 'info',
                        text: response.data.message,
                        showCancelButton: false,
                        confirmButtonText: 'Entendido',
                        focusConfirm: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });

                } else if (response.data.code === 215) {
                    this.$swal({
                        title: 'Ha ocurrido un error',
                        icon: 'info',
                        text: response.data.message,
                        showCancelButton: false,
                        confirmButtonText: 'Entendido',
                        focusConfirm: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                }

            } catch (error) {

                if (error.response.data.code === 300) {

                    this.$swal
                        .fire({
                            html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                            position: "center",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false, // Oculta el botón de confirmación
                            timer: 4000, // Duración en milisegundos (4 segundos)
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        })
                        .then(
                            () => {
                                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                console.log("closed by timer!!!!");

                                localStorage.clear();
                                location.reload(true);
                            },
                            (dismiss) => {
                                if (dismiss === "timer") {
                                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                    console.log("closed by timer!!!!");
                                    this.$swal.fire({
                                        title: "Finished!",
                                        icon: "success",
                                        showConfirmButton: false,
                                    });
                                }
                            }
                        );


                } else {
                    //this.$swal.close();
                    showErrorToast(error.response.data.messaje)
                }

            }


        }



    },
}
</script>
<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';

.modal-content {
    width: 800px !important;
}
</style>
  