
<template>
    <div>

        <VueFinalModal v-model="showModal" :esc-to-close="true" @closed="closed" class="modal-container"
            content-class="modal-content">


            <div class="modal__content">




                <div class="row">

                    <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1 " @click="closed">


                        <i class="fas fa-times-square close_modal"></i>

                    </div>

                    <div class="col-7 col-sm-7 col-md-7 col-xl-7 col-lg-7">

                        <p style=" font-weight: bold; font-size:14px; margin:0px; text-align: left;">Editar imagen</p>





                    </div>

                    <div class="col-4 col-sm-4 col-md-4 col-xl-4 col-lg-4">

                        <button id="btn_foto" @click="crop()" :disabled="isActiveFoto" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Guardar" class="btn btn-secondary btn-sm"
                            style="width: 100%;border-radius: 50px; padding: 5px 0px;">
                            Guardar
                        </button>



                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12" style="margin-top:15px">



                        <cropper ref="cropper" :auto-zoom="true" :stencil-component="$options.components.CircleStencil"
                            :src="image.src" :stencil-size="{
                                width: 500,
                                height: 500
                            }" />


                    </div>





                </div>







            </div>
        </VueFinalModal>


    </div>
</template>
   
<script>
/*eslint-disable */
import 'firebase/compat/storage'
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { VueFinalModal } from 'vue-final-modal'
import 'vue-final-modal/style.css'

export default {
    name: "ModalPhoto",
    components: {
        Cropper,
        CircleStencil,
        VueFinalModal
    },
    props: ['image', 'userId', 'tokenAuth'],
    data() {



        return {

            idUser: JSON.parse(localStorage.getItem('userData')).code,
            showModal: false,
            isActiveFoto: false,
            tokenAuth: 'Bearer ' + localStorage.getItem('authorization')

        }
    },
    activated() {



    },
    methods: {


        crop() {
            // Obtener el canvas del resultado del componente cropper
            const { canvas } = this.$refs.cropper.getResult();

            // Convertir el contenido del canvas a un objeto Blob
            canvas.toBlob((blob) => {
                // Hacer algo con el blob: subirlo a un servidor, descargarlo, etc.
                console.log(blob);

                // Activar indicador de foto activa
                this.isActiveFoto = true;

                // Crear un nombre de archivo único
                const d1 = new Date();
                let nameFile = this.idUser + "_" + d1.getTime();

                // Definir la ruta en Firebase Storage
                let dir = 'profilePhoto/' + this.idUser;

                // Referencia al almacenamiento de Firebase
                const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(blob);

                // Manejar el progreso de carga
                storageRef.on('state_changed',
                    snapshot => {
                        // Actualizar la interfaz de usuario durante la carga
                        document.getElementById('btn_foto').innerHTML = '<i class="fas fa-circle-notch fa-spin"></i> Subiendo ';
                    },
                    error => {
                        this.isActiveFoto = false;
                        document.getElementById('btn_foto').innerHTML = "Guardar";

                        // Manejar errores durante la carga
                        this.$toast.error("Ocurrió un error inesperado: " + error, {
                            position: "top-right",
                            max: 10
                        })
                    },
                    () => {
                        // Acciones después de que la carga se haya completado con éxito
                        storageRef.snapshot.ref.getDownloadURL().then(url => {
                            //console.log("URL de la imagen:", url);

                            /* const userData = JSON.parse(localStorage.getItem('userData'));
                            userData.photo = url;
                            const updatedUserData = JSON.stringify(userData);
                            localStorage.setItem('userData', updatedUserData); */

                            const updatedUserData = { ...JSON.parse(localStorage.getItem('userData')), photo: url };
                            localStorage.setItem('userData', JSON.stringify(updatedUserData));

                            this.updatePhoto(url)

                            this.$eventBus.emit('updateFotoPerfil')

                            document.getElementById('btn_foto').innerHTML = '<i class=""></i> Guardando';

                            this.$toast.success("Archivo subido con éxito", {
                                position: "top-right",
                                max: 10
                            })
                        });
                    }
                );
            }, this.image.type);
        },

        updatePhoto(url) {


            this.$https.post('api/v1/updatePhoto', { promoCode: this.idUser, photo: url }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.tokenAuth,
                }
            }).then(response => {

                if (response.data.code === 200) {
                    console.log('Foto Guardada');
                } else if (response.data.code === 200) {
                    this.$toast.error("Ocurrió un error inesperado", {
                        position: "top-right",
                        max: 10
                    })
                }


            }).catch(error => {
                this.$router.push({ name: 'login' })
            })

        },
        closed() {

            //this.$eventBus.emit('reiniciarModalPhoto');
        },


    },
    mounted() {


        this.isActiveFoto = false

        this.showModal = true;


    }
}
</script>
   
   
<style scoped></style>