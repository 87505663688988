import { createApp } from "vue";

import 'maz-ui/css/main.css'

import 'vue-select/dist/vue-select.css';

import App from "./App.vue";
import store from "./store";
import keys from '@/store/keys'
import router from "./router";
import API from '@/store/axios'
import API_WS from '@/store/axios_ws'
import PromSleep from '@/store/config';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import PinInput from 'v-pin-input'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import LoadScript from "vue-plugin-load-script";

import Toaster from '@meforma/vue-toaster';
import { vfmPlugin } from 'vue-final-modal'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { JSEncrypt } from 'jsencrypt'
import { Skeletor } from 'vue-skeletor';
import ReadMore from 'vue-read-more';
import VLazyImage from "v-lazy-image";


import vSelect from "vue-select";
import VueCleave from 'vue-cleave-component';
import Emittery from 'emittery';


const encryptBackoffice = new JSEncrypt()
encryptBackoffice.setPublicKey(keys.publicKeyBackoffice)

const ua = navigator.userAgent


const appFirebase = firebase.initializeApp({
  apiKey: "AIzaSyC8t4jErYSSbXI1uMdHv_tHBRiLa4pwNqE",
  authDomain: "referidos-sist.firebaseapp.com",
  projectId: "referidos-sist",
  storageBucket: "referidos-sist.appspot.com",
  messagingSenderId: "781587904774",
  appId: "1:781587904774:web:2e84a3adebca8a48848687"
})


const auth = firebase.auth()
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)




let isMobile=false;

if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
  console.log('Es mobile');
  //es mobile
isMobile=true;
} else {
  console.log('Es desktop');
 //es destopk
 isMobile=false;

}

const appInstance = createApp(App);
appInstance.config.globalProperties.$appFirebase = appFirebase;
appInstance.component('MazPhoneNumberInput', MazPhoneNumberInput)


appInstance.config.globalProperties.$encryptBackoffice = encryptBackoffice;
appInstance.config.globalProperties.$https = API;
appInstance.config.globalProperties.$https_ws = API_WS;

const promSleep = new PromSleep();
appInstance.config.globalProperties.$sleepTimer = promSleep;

appInstance.config.globalProperties.$isMobile = isMobile;
appInstance.config.globalProperties.$eventBus = new Emittery();
appInstance.use(store);
appInstance.use(router);
appInstance.use(ReadMore);



appInstance.use(VueCleave);
appInstance.use(LoadScript);

appInstance.use(VueGoogleMaps, {
  load: {
      //key: 'AIzaSyDAlcyvEoB_DzBgF4JMqr88HZViABFr8nY', //xtrim
      key: 'AIzaSyD3YpMyDORwKK_nssd9OmBwqlaiBJdAZTc',
      autobindAllEvents: true,
      libraries: "places"
  },
})



appInstance.component('v-select', vSelect);
appInstance.component('v-lazy-image', VLazyImage); 

appInstance.component(Skeletor.name, Skeletor);
appInstance.use(VueSweetalert2);
appInstance.use(Toaster);
appInstance.use(vfmPlugin);
appInstance.use(PinInput)

appInstance.use(ArgonDashboard);
appInstance.mount("#app");
