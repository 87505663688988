<template>
  <div>
    <div class="row">

      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card2">

          <div class="card-body">
            <div class="row">



              <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" align="center">

                <v-lazy-image :src="photo" class="rounded-circle" style="background: white;width: 40px;" />

              </div>

              <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <p
                  style="margin:0px; font-size:12px; text-transform: capitalize;   white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                  <b>{{ displayName }}</b>
                </p>
                <p style="text-align: left;font-size:11px; margin:0px; cursor:pointer" @click="editarRoutes">Editar mi
                  perfil</p>
              </div>

            </div>

          </div>
        </div>
      </div>


      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:10px;margin-bottom:10px">

        <div class="card2">

          <div class="card-body">

            <div class="d-flex">

              <h6 class="mb-0" style="font-size: 14px;" :class="this.$store.state.isRTL ? 'ms-2' : ''">
                Modo {{ modo }} activado
              </h6>





              <div class="form-check form-switch ps-0 ms-auto my-auto">
                <input class="form-check-input mt-1 ms-auto" type="checkbox" :checked="this.$store.state.darkMode"
                  @click="setDarkMode" />
              </div>
            </div>

          </div>

        </div>


      </div>



      <div v-if="esAdministracion" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center" style="margin-top:10px;margin-bottom:10px">

        <div class="card2">
          <div class="card-body">


            <a class="btn btn-primary btn-sm" v-on:click="ruta" style=" background: #757575!important; ">
              <i class="fas fa-user"></i> Administración
            </a>


          </div>
        </div>
      </div>








      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="    position: absolute; right: 0; bottom: 0; left: 0; padding: 1rem; text-align: center;">
        <button @click="exit()" class="btn btn-primary btn-sm" type="button" style="width:100%;">Cerrar sesión</button>
      </div>


    </div>
  </div>
</template>

<script>
/*eslint-disable */
import myMixin from '../mixin.js'
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";
import { JSEncrypt } from 'jsencrypt';
import keys from '@/store/keys';


export default {
  name: "Mas",
  data() {
    const { logout } = myMixin()

    return {
      esAdministracion: false,
      userId: '', //datos.userId,
      isMobile: this.$isMobile,
      tokenAuth: '', //datos.tokenAuth,
      logout: logout,
      photo: JSON.parse(localStorage.getItem('userData')).photo,
      displayName: JSON.parse(localStorage.getItem('userData')).displayName,
      modo: "",
    }
  },
  activated() {

    if (this.isMobile) {
      this.$eventBus.emit('activeShowMenu')
    }


  },
  props: ["toggle"],
  methods: {
    ruta(){
      this.$router.push({ name: 'Administracion' })
    },
    desencyptar(){
      const session = localStorage.getItem('session');
        const decrypt = new JSEncrypt();
        decrypt.setPrivateKey(keys.publicKeyBackoffice);
        const userRole = decrypt.decrypt(session);

        //console.log('Mi rol es: ' + userRole);

        if(userRole === 'administrador'){
          this.esAdministracion = true
        }
    },
    setDarkMode() {
      if (this.$store.state.darkMode) {
        localStorage.setItem('skin', "light")
        this.$store.state.darkMode = false;
        this.modo = "día";
        deactivateDarkMode();
        return;
      } else {
        localStorage.setItem('skin', "dark");
        this.$store.state.darkMode = true;
        this.modo = "noche";
        activateDarkMode();
      }
    },
    editarRoutes() {

      this.$router.push({ name: 'EditarPerfil' })
      this.$eventBus.emit('clearOverlaySidebar', false)
    },
    exit() {
      this.$eventBus.emit('clearOverlaySidebar', true)

      this.logout(true);
    }
  },
  mounted() {

    this.desencyptar();

    if (this.$store.state.darkMode) {
      this.modo = "noche";
    } else {
      this.modo = "día";
    }
  }

}    
</script>


<style scoped>
.form-check-input:checked {
  background-color: #00b63e !important;
  border-color: #00b63e !important
}
</style>