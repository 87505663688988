<template>
  <div class="card" style="margin-bottom:10px">

    <div class=" card-body">

      <div class="row">

        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

          <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i> SOLICITUDES
            DE RETIRO DE DINERO</p>

        </div>


        <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
          <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
          <i v-else @click="getListado()" style="cursor:pointer; margin:auto" class="fas fa-sync text-sm opacity-10"></i>
        </div>

      </div>

      <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

        <div class="row">

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

            <Skeletor :shimmer="true" height="40" width="50%" />

          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

            <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
            <Skeletor :shimmer="true" height="40" width="100%" />

          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                </tr>
              </thead>
              <tbody>


                <tr>

                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>





                </tr>
                <tr>

                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>





                </tr>
                <tr>

                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>





                </tr>

              </tbody>
            </table>

          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>


            </div>


          </div>

        </div>

      </div>

      <div v-else-if="notAllow" class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

          <img style="width:100px;" src="/img/caution.svg" />

          <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.</p>


        </div>

      </div>

      <DataTable v-else :columns="columns" :options="options" :data="transactions" class="table tablesorter" width="100%">
        <thead>
          <tr>
            <th>#</th>
            <th data-priority="1">Fecha/Hora</th>
            <th>idTransaccion</th>
            <th data-priority="2">Usuario</th>
            <th>DNI</th>
            <th>Movil</th>
            <th>Monto real</th>
            <th data-priority="3">Monto a transferirse</th>
            <th>Detalle del depósito</th>
            <th>Fecha/Acción</th>
            <th data-priority="4">Estado</th>
            <th data-priority="5">Acción</th>
          </tr>
        </thead>





      </DataTable>


    </div>

  </div>


  <VueFinalModal v-model="showModal" :esc-to-close="true" classes="modal-container" content-class="modal-content"
    style="display: flex; align-items: center; justify-content: center;">


    <div class="modal__content">

      <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" align="center">
        <h4 style=" margin-bottom: 10px; line-height: 28px; color: #67748e;" class="text-center">Datos del usuario</h4>
        <hr class="horizontal dark">

        <div class="row">


          <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 text-center">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-6 col-sm-6 col-xl-6 text-center">


                <p class="p_text"><span class="textLabel">Banco: </span> {{ user.bank }}</p>
                <p class="p_text"><span class="textLabel">Nro. cuenta: </span> {{ user.numberAccount }}</p>
                <p class="p_text"><span class="textLabel">Tipo: </span>{{ user.type }}</p>




              </div>

              <div class="col-lg-6 col-md-6 col-6 col-sm-6 col-xl-6 text-center">

                <p class="p_text"><span class="textLabel">Dni: </span>{{ user.dni }}</p>
                <p class="p_text"><span class="textLabel">Nombres: </span>{{ user.displayName }}</p>
                <p class="p_text"><span class="textLabel">Correo: </span>{{ user.email }}</p>



              </div>
            </div>

          </div>





          <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12  text-center">

            <h5 style=" margin-bottom: 10px; line-height: 28px; color: #67748e;" class="text-center mt-2">Datos del
              depósito</h5>
            <hr>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-6 col-sm-6 col-xl-6 text-center">

                <p class="p_text"><span class="textLabel">Fecha Solicitud: </span>{{ user.dateSolicitud }}</p>
                <p class="p_text"><span class="textLabel">Fecha Actualización: </span>{{ user.dateActualizacion }}</p>
                <p class="p_text"><span class="textLabel">Observación: </span>{{ transaccion.detalle }}</p>


              </div>

              <div class="col-lg-6 col-md-6 col-6 col-sm-6 col-xl-6 text-center">

                <p class="p_text"><span class="textLabel">Monto a Retirar: </span>${{ user.mounTransfer }}.00</p>
                <p class="p_text"><span class="textLabel">Número de comprobante: </span>{{ transaccion.comprobante }}</p>

              </div>
            </div>


          </div>

        </div>



      </div>



    </div>
  </VueFinalModal>


  <VueFinalModal v-model="showModal2" :esc-to-close="true" classes="modal-container" content-class="modal-content"
    style="display: flex; align-items: center; justify-content: center;">
    <div class="modal__content">
      <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" align="center">
        <h4 class="text-center" style="margin-bottom: 10px; line-height: 28px; color: #67748e;">
          Ingrese el comprobante
        </h4>
        <hr class="horizontal dark">

        <div class="row">
          <div class="col-lg-6 text-center">
            <p class="p_text"><span class="textLabel">Nombre: </span> {{ transaccion.displayName }}</p>
          </div>
          <div class="col-lg-6 text-center">
            <p class="p_text"><span class="textLabel">Id transacción: </span>{{ transaccion.cod_transactions }}</p>
          </div>
        </div>


        <div class="row">

          <div class="col-lg-6 text-center">
            <p class="p_text"><span class="textLabel">
                Comprobante</span>
              <input v-model="transaccion.comprobante" type="text" ref="myComprobante" class="form-control" required
                placeholder="Ingrese el comprobante" />
            </p>
          </div>
          <div class="col-lg-6 text-center">
            <p class="p_text">
              <span class="textLabel">Observación</span>
              <input v-model="transaccion.detalle" type="text" class="form-control" id="detalle"
                placeholder="Ingrese la observación" />
            </p>
          </div>

          <div class="text-center mt-3">
            <button class="btn btn-primary" v-on:click="getRegistrar">Registrar Comprobante</button>
          </div>



        </div>


      </div>
    </div>
  </VueFinalModal>
</template>

<script>

/* eslint-disable */

import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';
import $ from "jquery";
import { VueFinalModal } from 'vue-final-modal'
import 'vue-final-modal/style.css'
import { setTransitionHooks } from 'vue';

DataTable.use(DataTablesCore);

export default {
  components: {

    DataTable,
    VueFinalModal


  },
  props: ['prueba', 'idAdmin'],
  data() {

    return {
      loading: true,
      showModal: false,
      showModal2: false,
      transactions: [],
      notAllow: false,
      tokenAuth: 'Bearer ' + localStorage.getItem('authorization'),
      user: {
        displayName: "",
        dni: "",
        bank: "",
        numberAccount: "",
        email: '',
        type: "",

        dateSolicitud: "",
        dateActualizacion: "",
        mounTransfer: "",
        estado: ""
      },

      transaccion: {
        cod_transactions: '',
        displayName: '',
        mount: '',
        detalle: '',
        comprobante: ''
      },

      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            const index = meta.row + 1;
            return index;
          }
        },
        {
          data: 'fecha_solicitud',
          render: function (data, type, row, meta) {
            if (type === 'display' || type === 'filter') {
              const fechaHora = new Date(data);
              const fechaFormateada = fechaHora.toLocaleDateString('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              });
              const horaFormateada = fechaHora.toLocaleTimeString('es-ES', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              });
              return `${fechaFormateada} ${horaFormateada}`;
            }
            return data;
          },

        },

        { data: 'cod_transactions' },
        { data: 'displayName' },
        { data: 'dni' },
        { data: 'movil' },
        { data: 'balanceVentas' },
        { data: 'monto_retiro' },
        { data: 'pago_ventas' },
        { data: 'fech_aprobacion' },
        { data: 'estado' },
        {
          data: null,
          render: function (data, type, row, meta) {

            // Define una variable para almacenar el color del botón
            let buttonColor = "btn-primary"; // Por defecto, usa el color primario
            let colorFondo = ""

            // Verifica si data.estado es "depositado" y actualiza el color del botón
            if (data.estado === "depositado") {
              buttonColor = "btn-success"; // Cambia a color verde si el estado es "depositado"
              colorFondo = "background: green!important"
            }

            return (`
      <div class="dropdown">
        <button style= "${colorFondo}" class="btn ${buttonColor} btn-sm dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fas fa-ellipsis-v" style="margin-right:10px"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <li><button data-id="${data.cod_transactions}" data-displayname="${data.displayName}" data-userid="${data.idUser}" data-estado="${data.estado}" data-tipo="Aprobar" class="aprobar dropdown-item" type="button"><i class="fas fa-check-circle" style="margin-right:10px"></i>Depositado</button></li>
          <li><button data-id="${data.cod_transactions}" data-displayname="${data.displayName}" data-userid="${data.idUser}"  data-mount="${data.monto_retiro}" data-estado="${data.estado}" data-coddeposito="${data.cod_deposito}" data-detalle="${data.observacion}" data-tipo="Registrar" class="dropdown-item registrar" type="button"><i class="fas fa-file-alt" style="margin-right:10px"></i>Registrar Comprobante</button></li>
          <li><button  data-bank="${data.bank}" data-numberaccount="${data.numberAccount}" data-type="${data.type}" data-dni="${data.dni}" data-displayname="${data.displayName}" data-email="${data.email}" data-coddeposito="${data.cod_deposito}" data-detalle="${data.observacion}" data-mount="${data.monto_retiro}" data-fechasolicitud="${data.fecha_solicitud}" data-fechaprobacion="${data.fech_aprobacion}" class="dropdown-item verDatos" type="button"><i class="fas fa-eye" style="margin-right:10px"></i>Ver datos</button></li>
        </ul>
      </div>
    `);
          }
        }


      ], options: {
        responsive: true,
        "ordering": false,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 }
        ],
        lengthMenu: [
          [7, 15, 25, 35, 50, -1],
          [7, 15, 25, 35, 50, "All"],
        ],
        pageLength: 7,
        "language": {
          "search": "Buscar:",
          "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "searchPlaceholder": "Ingrese algún dato",
          "zeroRecords": "Lo sentimos, no se encontraron resultados",
          "processing": "Buscando. Espere por favor...",
          "sLengthMenu": "Mostrar _MENU_ registros",
          "oPaginate": {
            "sFirst": "Primero",
            "sLast": "Último",
            "sNext": "Siguiente",
            "sPrevious": "Anterior"
          },

        }
      },

    }
  },
  computed: {

  },
  watch: {

    'transaccion.detalle': function (detalle) {
      if (typeof this.transaccion.detalle === 'string') {
        this.transaccion.detalle = detalle.toUpperCase();
      }

    },

    'transaccion.comprobante': function (comprobante) {
      if (typeof this.transaccion.comprobante === 'string') {
        this.transaccion.comprobante = comprobante.toUpperCase();
      }

    }

  },
  mounted() {





    $(document).off('click', '.aprobar');
    $(document).off('click', '.registrar');


    $(document).on("click", ".aprobar", (evt) => {
      const cod_transactions = $(evt.currentTarget).data('id');
      const displayName = $(evt.currentTarget).data('displayname');
      const idUser = $(evt.currentTarget).data('userid');
      const estado = $(evt.currentTarget).data('estado');

      /* console.log(cod_transactions);
      console.log(displayName);
      console.log(idUser);
      console.log(estado); */

      this.deposito(cod_transactions, displayName, idUser, estado)


    });


    $(document).on("click", ".registrar", (evt) => {


      const cod_transactions = $(evt.target).data('id');
      const displayName = $(evt.currentTarget).data('displayname');
      const idUser = $(evt.currentTarget).data('userid');
      const mount = $(evt.target).data('mount');
      const estado = $(evt.currentTarget).data('estado');
      const cod_deposito = $(evt.currentTarget).data('coddeposito');
      const observacion = $(evt.currentTarget).data('detalle');



      /* console.log(cod_transactions);
      console.log(displayName);
      console.log(idUser);
      console.log(mount); */


      this.registrarDeposito(cod_transactions, displayName, mount, estado, cod_deposito, observacion)

    });




    $(document).off('click', '.verDatos');


    $(document).on("click", ".verDatos", (evt) => {

      const bank = $(evt.currentTarget).data('bank');
      const numberAccount = $(evt.currentTarget).data('numberaccount');
      const type = $(evt.currentTarget).data('type');
      const dni = $(evt.currentTarget).data('dni');
      const displayName = $(evt.currentTarget).data('displayname');
      const email = $(evt.currentTarget).data('email');
      const mount = $(evt.currentTarget).data('mount');
      const fecha_solicitud = $(evt.currentTarget).data('fechasolicitud');
      const fech_aprobacion = $(evt.currentTarget).data('fechaprobacion');
      const cod_deposito = $(evt.currentTarget).data('coddeposito');
      const observacion = $(evt.currentTarget).data('detalle');


      this.getVerDatos(bank, numberAccount, type, dni, displayName, email, mount, fech_aprobacion, fecha_solicitud, cod_deposito, observacion)

      /* console.log("bank:", bank);
      console.log("numberAccount:", numberAccount);
      console.log("type:", type);
      console.log("dni:", dni);
      console.log("displayName:", displayName);
      console.log("email:", email);
      console.log("mount:", mount);
      console.log('cod_deposito: ' + cod_deposito);
      console.log('observacion: ' + observacion); */


    });






    this.getListado();

  },
  methods: {


    getListado() {

      this.loading = true;

      this.$https.post('api/v1/getLoadRetiroAll', { idUser: '' }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.tokenAuth,
        }
      }).then(response => {


        //console.log(response);

        this.loading = false;

        if (response.data.code === 200) {
          this.transactions = response.data.datos

        } else if (response.data.code === 204) {
          this.transactions = []

        }




      }).catch(error => {

        this.$toast.error("Ocurrió un error inesperado", {
          position: "top-right",
          max: 10
        })

      })



    },


    getVerDatos(bank, numberAccount, type, dni, displayName, email, mount, fech_aprobacion, fecha_solicitud, cod_deposito, observacion) {

      this.showModal = true;

      this.user = {
        bank,
        numberAccount,
        type,
        dni,
        displayName,
        email,
        mounTransfer: mount,
        dateSolicitud: fecha_solicitud,
        dateActualizacion: fech_aprobacion
      }


      this.transaccion.detalle = observacion,
        this.transaccion.comprobante = cod_deposito



    },

    deposito(cod_transactions, displayName, idUser, estado) {



      if (estado === 'depositado') {
        this.$swal({
          title: 'CONTROL DEL SISTEMA',
          icon: 'info',
          text: 'Esta transacción ya se encuentra depositada.',
          showCancelButton: false,
          confirmButtonText: 'Entendido',
          focusConfirm: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });

        return
      }





      this.$swal({
        title: `¿Está seguro de que quiere registrar este depósito?`,
        icon: 'question',
        width: '400px',
        showCancelButton: true,
        confirmButtonText: 'Registrar depósito',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {

        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {




          this.$https.post('api/v1/getAceptaTransferencia', { cod_transactions, idUser }, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": this.tokenAuth,
            }
          }).then(response => {

            console.log('getAceptaTransferencia');
            console.log(response);

            if (response.data.code === 204) {
              this.$swal({
                title: 'CONTROL DEL SISTEMA',
                icon: 'info',
                text: response.data.message,
                showCancelButton: false,
                confirmButtonText: 'Entendido',
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });

            } else if (response.data.code === 200) {

              this.getListado()


              this.$swal({
                title: 'CONTROL DEL SISTEMA',
                icon: 'success',
                text: 'Grabación éxitosa',
                showCancelButton: false,
                confirmButtonText: 'Entendido',
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });

            }

          }).catch(error => {


            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10
            })
          })








        }
      });






    },


    registrarDeposito(cod_transactions, displayName, mount, estado, cod_deposito, observacion) {

      if (estado === 'pendiente') {
        this.$toast.error("No se puede registrar el comprobante, si la transacción se encuentra pendiente", {
          position: "top-right",
          max: 10
        })

        return
      }
      this.showModal2 = true



      this.transaccion.displayName = displayName;
      this.transaccion.cod_transactions = cod_transactions;
      this.transaccion.detalle = observacion,
        this.transaccion.comprobante = cod_deposito




    },

    getRegistrar() {

      if (typeof this.transaccion.comprobante === 'string') {
        this.transaccion.comprobante = this.transaccion.comprobante.trim();
      }

      if (!this.transaccion.comprobante) {
        this.$toast.error("El comprobante no debe estar vacio", {
          position: "top-right",
          max: 10
        })
      }





      this.$swal({
        title: `¿Está seguro de que quiere registrar el comprobante?`,
        icon: 'question',
        width: '400px',
        showCancelButton: true,
        confirmButtonText: 'Registrar ahora!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {

        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {


          this.showModal2 = false;
          console.log(this.transaccion.comprobante);
          console.log(this.transaccion.detalle);
          console.log(this.transaccion.cod_transactions);

          //getRegistrarDeposito

          let datos = {
            cod_transactions: this.transaccion.cod_transactions,
            comprobante: this.transaccion.comprobante,
            detalle: this.transaccion.detalle
          }



          this.$https.post('api/v1/getRegistrarDeposito', datos, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": this.tokenAuth,
            }
          }).then(response => {


            if (response.data.code === 204) {
              this.$swal({
                title: 'CONTROL DEL SISTEMA',
                icon: 'info',
                text: response.data.message,
                showCancelButton: false,
                confirmButtonText: 'Entendido',
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });

            } else if (response.data.code === 200) {



              this.getListado()


              this.$swal({
                title: 'CONTROL DEL SISTEMA',
                icon: 'success',
                text: 'Registro Éxitoso',
                showCancelButton: false,
                confirmButtonText: 'Entendido',
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });


            }




          }).catch(error => {


            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10
            })
          })






        }



      })










    }




  },
}
</script>

<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
  